import React, { useEffect, useRef, useState } from "react";
import "./Main_Add_Service.css";
import InitiateEnrollment from "./components/Initiation/InitiationMain";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import Address from "./components/Address/Address";
import Agree from "./components/Confirmation/Confirmation";
import PaymentScreen from "./components/PaymentScreen/PaymentScreen";
import Axios from "axios";
import Preview from "./components/Preview/Preview";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
export default function PostpaidAddServiceMain({ isPostpaidIncomplete, setIsPostpaidIncomplete }) {
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [initiateActive, setInitiateActive] = useState(isPostpaidIncomplete !== "" ? false : true);
    const [personalInfoActive, setPersonalInfoActive] = useState(isPostpaidIncomplete !== "" ? true : false);
    const [addressActive, setAddressActive] = useState(false);
    let selectedId = JSON.parse(localStorage.getItem("selectedId"));
    const [confirmationActive, setConfirmationActive] = useState(false);
    const [checked, setChecked] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.shippingLabelDiscount : "");
    const [paymentScreenActive, setPaymentScreenActive] = useState(false);
    const [previewScreenActive, setPreviewScreenActive] = useState(false);
    const [billingModelSelected, setBillingModelSelected] = useState("");
    const [currentBillingModel, setCurrentBillingModel] = useState();
    const [parentDetails, setParentDetails] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete : "");
    const [additionalsDiscounts, setAdditionalDiscounts] = useState({
        additional: [],
        setted: false,
        discounts: [],
    });

    const [parentCurrentContact, setParentCurrentContact] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.contact : "");
    const [initiationData, setInitiationData] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete : "");
    const [paymentScreenDetails, setPaymentScreenDetails] = useState({});
    const [currentAllPlans, setCurrentAllPlans] = useState();
    const [currentAllBillingConfig, setCurrentAllBillingConfig] = useState("");
    const [paymentDone, setPaymentDone] = useState(false);
    const [dataSendForInvoice, setDataSendForInvoice] = useState("");
    const [allpaymentinfo, setAllPaymentInfo] = useState("");
    const [proceed, setProceed] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [carrierVal, setCarrierVal] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.carrierVal : "");
    const [servicesVal, setServicesVal] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.servicesVal : "");
    const [deliveryVal, setDeliveryVal] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.deliveryVal : "");

    const [checkLabelVal, setCheckLabelVal] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.labelSubmissionval : "");
    const [generate, setGenerate] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.shippingLabelDiscount : "");
    const [prospectCases, setProspectCases] = useState({ prospectWithInvoice: false, prospectWithoutInvoice: false });
    const [amount, setAmount] = useState(0);
    const [zipVerified, setZipVerified] = useState(isPostpaidIncomplete !== "" ? isPostpaidIncomplete?.izZipVerified : false);
    const [currentPlanSelect, setCurrentPlanSelect] = useState(paymentDone ? paymentScreenDetails?.currentPlanSelected : "");
    const [current, setCurrentSelect] = useState(paymentDone ? paymentScreenDetails?.inventorySelected : "");

    const [validateAddress, setValidateAddress] = useState(false);
    const [storeValidAddress, setStoreValidAddress] = useState("");
    const [serviceAddress1, setServiceAddress1] = useState("");
    const [serviceAddress2, setServiceAddress2] = useState("");
    const [mailingAddress1, setMailingAddress1] = useState("");
    const [mailingAddress2, setMailingAddress2] = useState("");
    const [poBoxAddress, setPoBoxAddress] = useState("");
    useEffect(() => {
        const loginRes = localStorage.getItem("userData");
        const parseLoginRes = JSON.parse(loginRes);
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`).then((res) => {
            let billingmodels = res?.data?.data;
            for (let i = 0; i < billingmodels.length; i++) {
                if (billingmodels[i].billingModel === "POSTPAID") {
                    setCurrentBillingModel(billingmodels[i].billingModel);
                    setBillingModelSelected(billingmodels[i]._id);
                }

                if (isPostpaidIncomplete) {
                    setProceed(true);
                    setParentDetails(isPostpaidIncomplete);
                    setInitiationData(isPostpaidIncomplete);
                    setInitiateActive(false);
                    setPersonalInfoActive(true);
                } else {
                    setProceed(true);
                }
            }
        });

        return () => {
            setIsPostpaidIncomplete("");
        };
    }, []);
    return (
        <div className="linkedmain">
            <>
                <div className="flex flex-wrap gap4 flex-row justify-content-left gap-4">
                    <p className={`linkedmainheader ${initiateActive ? "linkedmainheaderactive" : ""}`}>Initiation</p>
                    <p className={`linkedmainheader ${personalInfoActive ? "linkedmainheaderactive" : ""}`}>Personal Info</p>
                    <p className={`linkedmainheader ${addressActive ? "linkedmainheaderactive" : ""}`}>Address</p>
                    <p className={`linkedmainheader ${confirmationActive ? "linkedmainheaderactive" : ""}`}>Confirmation</p>

                    <p className={`linkedmainheader ${paymentScreenActive ? "linkedmainheaderactive" : ""}`}>Product</p>

                    <p className={`linkedmainheader ${previewScreenActive ? "linkedmainheaderactive" : ""}`}>Preview</p>
                </div>
                {initiateActive ? (
                    <InitiateEnrollment
                        setParentDetails={setParentDetails}
                        setInitiationData={setInitiationData}
                        parentDetails={parentDetails}
                        setCurrentBillingModel={setCurrentBillingModel}
                        billingModelSelected={billingModelSelected}
                        setBillingModelSelected={setBillingModelSelected}
                        setPersonalInfoActive={setPersonalInfoActive}
                        setInitiateActive={setInitiateActive}
                        setZipVerified={setZipVerified}
                    />
                ) : personalInfoActive ? (
                    <PersonalInfo
                        isPostpaidIncomplete={isPostpaidIncomplete}
                        parentCurrentContact={parentCurrentContact}
                        setParentDetails={setParentDetails}
                        initiationData={initiationData}
                        zipVerified={zipVerified}
                        currentBillingModel={currentBillingModel}
                        parentDetails={parentDetails}
                        setInitiateActive={setInitiateActive}
                        setPersonalInfoActive={setPersonalInfoActive}
                        setAddressActive={setAddressActive}
                    />
                ) : addressActive ? (
                    <Address
                        zipVerified={zipVerified}
                        isPostpaidIncomplete={isPostpaidIncomplete}
                        parentDetails={parentDetails}
                        initiationData={initiationData}
                        setParentDetails={setParentDetails}
                        setAddressActive={setAddressActive}
                        setPersonalInfoActive={setPersonalInfoActive}
                        setConfirmationActive={setConfirmationActive}
                        validateAddress={validateAddress}
                        setValidateAddress={setValidateAddress}
                        storeValidAddress={storeValidAddress}
                        setStoreValidAddress={setStoreValidAddress}
                        serviceAddress1={serviceAddress1}
                        setServiceAddress1={setServiceAddress1}
                        serviceAddress2={serviceAddress2}
                        setServiceAddress2={setServiceAddress2}
                        mailingAddress1={mailingAddress1}
                        setMailingAddress1={setMailingAddress1}
                        mailingAddress2={mailingAddress2}
                        setMailingAddress2={setMailingAddress2}
                        poBoxAddress={poBoxAddress}
                        setPoBoxAddress={setPoBoxAddress}
                        incomplete={isPostpaidIncomplete ? true : false}
                    />
                ) : confirmationActive ? (
                    <Agree
                        setParentDetails={setParentDetails}
                        setPaymentScreenActive={setPaymentScreenActive}
                        parentDetails={parentDetails}
                        initiationData={initiationData}
                        setAddressActive={setAddressActive}
                        setPreviewScreenActive={setPreviewScreenActive}
                        isPostpaidIncomplete={isPostpaidIncomplete}
                        setConfirmationActive={setConfirmationActive}
                    />
                ) : paymentScreenActive ? (
                    <PaymentScreen
                        currentAllPlans={currentAllPlans}
                        prospectCases={prospectCases}
                        setProspectCases={setProspectCases}
                        setCurrentAllBillingConfig={setCurrentAllBillingConfig}
                        setCurrentAllPlans={setCurrentAllPlans}
                        setAllPaymentInfo={setAllPaymentInfo}
                        setDataSendForInvoice={setDataSendForInvoice}
                        paymentScreenDetails={paymentScreenDetails}
                        paymentDone={paymentDone}
                        setPaymentDone={setPaymentDone}
                        initiationData={initiationData}
                        parentDetails={parentDetails}
                        setPaymentScreenActive={setPaymentScreenActive}
                        currentBillingModel={currentBillingModel}
                        setConfirmationActive={setConfirmationActive}
                        billingModelSelected={billingModelSelected}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setPaymentScreenDetails={setPaymentScreenDetails}
                        carrierVal={carrierVal}
                        setCarrierVal={setCarrierVal}
                        servicesVal={servicesVal}
                        setServicesVal={setServicesVal}
                        deliveryVal={deliveryVal}
                        setDeliveryVal={setDeliveryVal}
                        checkLabelVal={checkLabelVal}
                        setCheckLabelVal={setCheckLabelVal}
                        checked={checked}
                        setChecked={setChecked}
                        generate={generate}
                        setGenerate={setGenerate}
                        currentPlanSelect={currentPlanSelect}
                        setCurrentPlanSelect={setCurrentPlanSelect}
                        current={current}
                        additionalsDiscounts={additionalsDiscounts}
                        setAdditionalDiscounts={setAdditionalDiscounts}
                        setCurrentSelect={setCurrentSelect}
                        amount={amount}
                        setAmount={setAmount}
                        // product={product}
                        // setProduct={setProduct}
                        // current={current}
                        // setCurrentSelect={setCurrentSelect}
                        // currentPlanSelect={currentPlanSelect}
                        // setCurrentPlanSelect={setCurrentPlanSelect}
                    />
                ) : previewScreenActive ? (
                    <Preview
                        paymentDone={paymentDone}
                        prospectCases={prospectCases}
                        isPostpaidIncomplete={isPostpaidIncomplete}
                        setConfirmationActive={setConfirmationActive}
                        currentAllBillingConfig={currentAllBillingConfig}
                        currentAllPlans={currentAllPlans}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setPaymentScreenActive={setPaymentScreenActive}
                        initiationData={initiationData}
                        parentDetails={parentDetails}
                        paymentScreenDetails={paymentScreenDetails}
                    />
                ) : (
                    ""
                )}
            </>

            <Toast ref={toast} />
        </div>
    );
}
