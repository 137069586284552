import React, { useEffect, useState } from "react";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const QueryNetwork = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [mdn, setMdn] = useState("");
    const [data, setData] = useState("");
    const [disable, setDisable] = useState(false);
    const [cpData, setCpData] = useState("");
    const getQueryNetwork = async () => {
        setIsLoading(true);

        try {
            const data = localStorage.getItem("basicData");
            const parsedData = JSON.parse(data);
            const resp = await Axios.get(`${BASE_URL}/api/user/QueryNetwork?enrollmentId=${localStorage.getItem("selectedId")}&mdn=${mdn}`);
            setData(resp?.data?.data);
            if (resp?.status === 200 || resp?.status === 201) {
                setIsLoading(false);
                toast.success(resp?.data?.msg);
            }
        } catch (error) {
            toast.error(`${error?.response?.data?.msg}: ${error?.response?.data?.status?.errors?.error?.message}`);
            setIsLoading(false);
        }
    };
    const handleMdn = (e) => {
        const inputMdn = e.target.value;
        const sanitizedMdn = inputMdn.replace(/\D/g, "");
        const trimmedMdn = sanitizedMdn.slice(0, 10);
        setMdn(trimmedMdn);
    };
    const userId = JSON.parse(localStorage.getItem("selectedId"));

    useEffect(() => {
        const getUserData = async () => {
            try {
                const res = await Axios.get(`${BASE_URL}/api/user/userDetails?userId=${userId}`);
                if (res?.status === 200 || res?.status === 201) {
                    setCpData(res?.data?.data);
                }
            } catch (e) {
      
            }
        };
        getUserData();
    }, []);
    return (
        <div className="card">
            <div flex flex-wrap justify-content-left>
                <h2 style={{ fontWeight: "600" }}>Query Network</h2>
            </div>
            <br />
            <div className=" flex flex-wrap w-full  justify-content-between">
                <h1 style={{ fontSize: "14px", fontWeight: "600", paddingTop: "10px" }}>MDN</h1>
                <InputText disabled={disable} style={{ width: "70%" }} value={mdn} onChange={handleMdn} />
            </div>
            <div className="flex flex-wrap w-full justify-content-end mt-4">
                <Button
                    loading={isLoading}
                    label="Submit"
                    onClick={() => {
                        if (cpData.esn === undefined) {
                            toast.error("Esn is not Assigned");
                            setIsLoading(false);
                            return;
                        } else if (mdn.length < 10) {
                            toast.error("Please enter a valid MDN");
                            setIsLoading(false);

                            return;
                        } else {
                            setDisable(true);
                            getQueryNetwork();
                        }
                    }}
                />
            </div>
            <div className=" flex justify-content-center">
                <div className="flex flex-wrap justtify-content-around w-full mt-4 pt-4" style={{ backgroundColor: "#ebfaf8" }}>
                    <div className="justify-content-left pl-4 " style={{ width: "50%" }}>
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>MDN</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>ESN</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>IMSI</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>IMEI</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>Sim Status</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>MS Status</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>SOC</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>APNs</h1>
                        </div>
                        <br />
                    </div>

                    <div className="justify-content-left " style={{ width: "50%" }}>
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{mdn}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.sim}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.IMSI}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.IMEI}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.SIMSTATUS}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.MS_STATUS}</h1>
                        </div>

                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>
                                {data?.socs?.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        {item.soc}
                                        {index !== data.socs.length - 1 && ", "}
                                    </React.Fragment>
                                ))}
                            </h1>
                        </div>
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>
                                {data?.apn?.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        {item.value}
                                        {index !== data.apn.length - 1 && ", "}
                                    </React.Fragment>
                                ))}
                            </h1>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueryNetwork;
