import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RadioButton } from "primereact/radiobutton";
import { Tickbtn } from "../../../../../../../utility";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { ToastContainer, toast } from "react-toastify";
import CardAuthPayment from "./dialog/CardAuthPayment";
import EcheckAuthPayment from "./dialog/EcheckAuthPayment";
import { Toast } from "primereact/toast";
import Payment from "./dialog/Stripe_Updated";
import "./PaymentScreen.css";
import CommonLoaderBlue from "./dialog/loader/LoaderBlue";
export default function PaymentScreen({
    setAllPaymentInfo,
    setCurrentAllPlans,
    payingAmount,
    setPayingAmount,
    setCurrentAllBillingConfig,
    setAllpaymentDone,
    setDataSendForInvoice,
    paymentDone,
    paymentScreenDetails,
    setPaymentDone,
    additionalsDiscounts,
    setAdditionalDiscounts,
    setPaymentScreenDetails,
    initiationData,
    billingModelSelected,
    inventoryIdSelected,
    setInventoryIdSelected,
    checked,
    setChecked,
    setPreviewScreenActive,
    currentBillingModel,
    setPaymentScreenActive,
    setConfirmationActive,
    toast,
    parentDetails,
    carrierVal,
    setCarrierVal,
    servicesVal,
    setServicesVal,
    deliveryVal,
    setDeliveryVal,
    checkLabelVal,
    setCheckLabelVal,
    generate,
    setGenerate,
    currentPlanSelect,
    setCurrentPlanSelect,
    current,
    inventoryTypeSelected,
    setInventoryTypeSelected,
    setCurrentSelect,
    amount,
    prospectCases,
    setProspectCases,
    currentScreen,
    setCurrentScreen,
    isPrepaidIncomplete,
    setAmount,
}) {
    const [inventory, setInventory] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentmethoderror, setpaymentmethoderror] = useState(false);
    const [currentPlanName, setCurrentPlanName] = useState("");
    const [currentBillingConfig, setCurrentBillingConfig] = useState();
    const [dialogForCardAuth, setdialogForCardAuth] = useState(false);
    const [dialogForEcheck, setDialogForEcheck] = useState(false);
    const [additional, setAdditional] = useState([]);
    const [paidAmountRequired, setPaidAmountRequired] = useState(false);
    const [previousplanprice, setPreviousPlanPrice] = useState(0);
    const [discount, setDiscount] = useState([]);
    //const [checked, setChecked] = useState(false);
    const _id = initiationData?._id;
    const [isLoading, setIsLoading] = useState(false);
    const [propectWithInvoice, setProspectWithInvoice] = useState(prospectCases?.prospectWithInvoice);
    const [propectWithOutInvoice, setProspectWithOutInvoice] = useState(prospectCases?.prospectWithoutInvoice);
    // const shippingLabelDiscount = parseLabelSubmissionVal?.data?.shippingLabelDiscount;
    const [carrier, setCarrier] = useState([]);
    const [expectedCost, setExpectedCost] = useState(false);
    const [services, setServices] = useState([]);
    const [rates, setRates] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [ratesLoading, setRatesLoading] = useState(false);
    const [previousRate, setPreviousRate] = useState();
    localStorage.setItem("generatelabel", generate);
    const deliveryFedEx = [{ name: "none" }, { name: "delivery" }, { name: "signature" }, { name: "direct_signature" }, { name: "adult_signature" }];

    localStorage.setItem("labelCheck", checkLabelVal);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const [currentPlanSelect, setCurrentPlanSelect] = useState(paymentDone ? paymentScreenDetails?.currentPlanSelected : "");
    const validationSchema = Yup.object().shape({
        billId: Yup.string().required("Product is required"),
        plan: Yup.string().required("Plan is required"),
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error block">{formik.errors[name]}</small>;
    };
    // const [current, setCurrentSelect] = useState(paymentDone ? paymentScreenDetails?.inventorySelected : "");

    const [plan, setPlans] = useState([]);
    function capitalizeSentence(sentence) {
        // Split the sentence into words
        const words = sentence.split(" ");

        // Capitalize the first letter of each word and convert the rest to lowercase
        const capitalizedWords = words.map((word) => {
            // Convert the first letter to uppercase and the rest to lowercase
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });

        // Join the words back into a sentence
        return capitalizedWords.join(" ");
    }

    const [paymentDialogVisibility, setPaymentDialogVisibility] = useState(false);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            plan: currentPlanSelect ? currentPlanSelect : paymentDone ? paymentScreenDetails?.plan : "",
            billId: current ? current : paymentDone ? paymentScreenDetails?.billId : "",
            paymentMode: paymentDone ? paymentScreenDetails?.paymentMode : isPrepaidIncomplete !== "" && isPrepaidIncomplete?.paymentMethod ? isPrepaidIncomplete?.paymentMethod : "",
            discount: additionalsDiscounts.discounts,
            additional: additionalsDiscounts.additional,
            totalamount: "",
            paid: payingAmount,
            customerid: paymentDone ? paymentScreenDetails?.customerid : _id,
            type: "Sign Up ",
            inventorySelected: paymentDone ? paymentScreenDetails?.inventorySelected : current,
            currentPlanSelected: paymentDone ? paymentScreenDetails?.currentPlanSelected : "",
            discounts: paymentDone ? paymentScreenDetails?.discounts : "",
            prospectwithinvoice: prospectCases?.prospectWithInvoice,
            prospectwithoutinvoice: prospectCases?.prospectWithoutInvoice,
        },
        onSubmit: async (values, actions) => {
            if (formik.values.prospectwithoutinvoice || formik.values.prospectwithinvoice) {
                setPaymentScreenDetails(formik.values);
                setPaymentScreenActive(false);
                setPreviewScreenActive(true);
            } else {
                if (formik.values.paymentMode === "Skip Payment" && parentDetails?.accountType === "Postpaid") {
                    setPaymentScreenDetails(formik.values);
                    if (paymentDone) {
                        setPaymentScreenActive(false);
                        setPreviewScreenActive(true);
                    } else {
                        setPaymentDone(true);
                        setIsLoading(true);
                        let planCharges = 0;
                        let planName = "";
                        for (let i = 0; i < plan?.length; i++) {
                            if (formik.values.plan === plan[i]?._id) {
                                planName = plan[i]?.name;
                                planCharges = plan[i]?.price;
                            }
                        }
                        const dataToSend = {
                            invoiceType: "Sign Up",
                            customerId: formik.values.customerid,
                            planId: formik.values.plan,
                            planCharges: planCharges,
                            additionalCharges: formik.values.additional,
                            discount: formik.values.discount,
                            totalAmount: formik.values.totalamount,
                            amountPaid: "0",
                            invoiceDueDate: currentBillingConfig?.dueDate,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            invoiceStatus: "Pending",
                            paymentMethod: "Skip Payment",
                            invoiceOneTimeCharges: currentBillingConfig?.oneTimeCharge,
                            lateFee: currentBillingConfig?.applyLateFee,
                            planName: planName,
                            chargingType: "Monthly",
                            printSetting: "Both",
                            productName: "",
                            selectProduct: formik.values.billId,
                        };
                        try {
                            const response = await Axios.post(`${BASE_URL}/api/user/postpaidpaymentDetails`, dataToSend);
                            if (response?.status === 201 || response?.status === 200) {
                                setDataSendForInvoice(dataToSend);
                                setAllPaymentInfo(response?.data);

                                setIsLoading(false);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            }
                        } catch (error) {
                            toast.current.show({ severity: "error", summary: "Payment Details", detail: error?.response?.data?.msg });

                            setIsLoading(false);
                        }
                    }
                } else if (formik.values.paymentMode === "Skip Payment" && parentDetails?.accountType === "Prepaid") {
                    setPaymentScreenDetails(formik.values);
                    if (paymentDone) {
                        setPaymentScreenActive(false);
                        setPreviewScreenActive(true);
                    } else {
                        let object = formik.values;
                        let checkLabel;
                        try {
                            const storedItem = localStorage.getItem("checked");
                            checkLabel = storedItem ? JSON.parse(storedItem) : false;
                        } catch (error) {
                            checkLabel = false;
                        }

                        const shipmentName = localStorage.getItem("shipmentName");
                        const shipmentCost = localStorage.getItem("shipmentCost");
                        let additionalFeature = [];
                        let discounts = [];
                        setPaymentDone(true);
                        let dueDate = "";
                        let applyLateFee = "";
                        let oneTimeCharge = "";
                        let planName = "";
                        let planId = "";
                        let planCharges = "";
                        let selectedDiscounts = formik.values.discount;
                        dueDate = currentBillingConfig?.dueDate;
                        oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                        applyLateFee = currentBillingConfig?.applyLateFee;
                        for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                            for (let k = 0; k < selectedDiscounts?.length; k++) {
                                if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                    let obj = {
                                        name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                        amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                    };
                                    discounts.push(obj);
                                }
                            }
                        }
                        for (let i = 0; i < plan?.length; i++) {
                            if (object.plan === plan[i]?._id) {
                                planName = plan[i]?.name;
                                planCharges = plan[i]?.price;

                                planId = plan[i]?._id;
                            }
                        }

                        let additionalFeatures = formik.values.additional;
                        for (let k = 0; k < additionalFeatures?.length; k++) {
                            for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                    let obj = {
                                        name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                        amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                    };
                                    additionalFeature.push(obj);
                                }
                            }
                        }

                        const loginRes = localStorage.getItem("userData");
                        const parseLoginRes = JSON.parse(loginRes);

                        let dataToSend = {
                            isAutopay: false,
                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: "0",
                            selectProduct: object?.billId,
                            invoiceDueDate: dueDate,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus: "Unpaid",
                            planId: formik.values.plan,
                            planName: planName,
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod: "skip",
                            printSetting: "Both",
                            isInvoice: true,
                            isWithInvoice: true,
                            userId: parseLoginRes?._id,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);
                        Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => {
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            })
                            .catch((err) => {});
                    }
                } else if (formik.values.paymentMode === "Credit Card (Authorize)" || formik.values.paymentMode === "Credit Card" || formik.values.paymentMode === "E-Check (Authorize)") {
                    setPaymentScreenDetails(formik.values);
                    if (paymentDone) {
                        setPaymentScreenActive(false);
                        setPreviewScreenActive(true);
                    } else {
                        if (formik.values.paymentMode === "E-Check (Authorize)") {
                            setDialogForEcheck(true);
                        } else if (formik.values.paymentMode == "Credit Card (Authorize)") {
                            setdialogForCardAuth(true);
                        } else if (formik.values.paymentMode == "Credit Card" && parseLoginRes?.companyName?.toUpperCase()?.includes("TALKDAILY")) {
                            setdialogForCardAuth(true);
                        } else if (formik.values.paymentMode == "Credit Card") {
                            if (formik.values.paid !== "") {
                                setPaymentDialogVisibility(true);
                            } else {
                                setPaidAmountRequired(true);
                            }
                        } else {
                            setdialogForCardAuth(true);
                        }
                    }
                } else {
                    setpaymentmethoderror(true);
                }
            }
        },
    });
    useEffect(() => {
        formik.setFieldValue("totalamount", parseFloat(formik.values.totalamount)?.toFixed(2));
        setAmount(parseFloat(formik.values.totalamount)?.toFixed(2));
    }, [formik.values.totalamount]);
    useEffect(() => {
        if (carrierVal !== "") {
            const getServices = async () => {
                const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/listServices?carrierCode=${carrierVal}`);
                const data = response?.data?.data;
                setServices(data);
            };
            if (carrierVal !== undefined) {
                getServices();
            }
        }
    }, [carrierVal]);

    useEffect(() => {
        const delivery = async () => {
            const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/getDeliveryConfirmation`);
            const data = response?.data;
            setDelivery(data);
        };
        delivery();
        const getCarrier = async () => {
            const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/carriersList`);
            const data = response?.data?.data;

            setCarrier(data);
        };
        getCarrier();
    }, []);

    useEffect(() => {
        if (carrierVal !== "" && servicesVal !== "" && deliveryVal !== "") {
            setRatesLoading(true);
            const dataToSend = {
                serviceprovider: parentDetails?.serviceProvider,
                carrierCode: carrierVal,
                serviceCode: servicesVal,
                toPostalCode: parentDetails?.isSameServiceAddress ? parentDetails?.zip : parentDetails?.isNotSameServiceAddress ? parentDetails?.mailingZip : parentDetails?.isPoBoxAddress ? parentDetails?.poBoxZip : parentDetails?.zip,
                toState: parentDetails?.isSameServiceAddress ? parentDetails?.state?.toUpperCase() : parentDetails?.isNotSameServiceAddress ? parentDetails?.mailingState?.toUpperCase() : parentDetails?.isPoBoxAddress ? parentDetails?.poBoxState?.toUpperCase() : parentDetails?.state?.toUpperCase(),
                toCity: parentDetails?.isSameServiceAddress ? parentDetails?.city?.toUpperCase() : parentDetails?.isNotSameServiceAddress ? parentDetails?.mailingCity?.toUpperCase() : parentDetails?.isPoBoxAddress ? parentDetails?.poBoxCity?.toUpperCase() : parentDetails?.city?.toUpperCase(),
                toCountry: "US",
                packageCode: "",
                inventoryType: current,
                confirmation: deliveryVal,
                inventoryType: inventoryIdSelected,
                residential: "true",
            };
            const getRates = async () => {
                if (checkLabelVal) {
                    try {
                        const response = await Axios.post(`${BASE_URL}/api/web/shipment/getRates`, dataToSend);
                        if (response?.data?.msg && response?.data?.data === undefined) {
                            toast.current.show({ severity: "error", summary: "Shipment Cost", detail: response?.data?.msg });
                            setExpectedCost(false);
                            setRatesLoading(false);
                            return;
                        }
                        const data = response?.data?.data;

                        if (Array.isArray(data) && data.length > 0) {
                            setRates(data);
                            setRatesLoading(false);
                            setExpectedCost(true);
                            localStorage.setItem("checked", false);
                        } else {
                            setRatesLoading(false);
                        }
                    } catch (error) {
                        // toast.current.show({ severity: "error", summary: "Shipment Cost", detail: "The package exceeds the maximum allowable weight of 15.999 ounces" });
                    }
                }
            };

            getRates();
        } else {
        }
    }, [carrierVal && servicesVal, deliveryVal, current]);
    useEffect(() => {
        Axios.get(`${BASE_URL}/api/inventoryType/getInventorybymodel?billingModel=${billingModelSelected}&serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let arrayofbillingmodel = [];
                for (let i = 0; i < res?.data.data?.length; i++) {
                    let obj = {};
                    obj.label = res?.data?.data[i]?.inventoryType;
                    obj.value = res?.data?.data[i]?.inventoryType;
                    obj.image = res?.data?.data[i]?.image;
                    arrayofbillingmodel.push(obj);
                }

                setInventory(arrayofbillingmodel);
                setLoader(false);
            })
            .catch((err) => {});
    }, []);
    useEffect(() => {
        if (current !== "") {
            Axios.get(`${BASE_URL}/api/user/BillingConfiguration?inventoryType=${inventoryTypeSelected}&make=${current?.substring(0, current.indexOf("-") - 1)}&model=${current?.substring(current.lastIndexOf("-") + 2)}&billingmodel=${currentBillingModel}&ServiceProvider=${parseLoginRes?.company}`)
                .then((res1) => {
                    setPlans(res1?.data);
                    setCurrentAllPlans(res1?.data);

                    Axios.get(
                        `${BASE_URL}/api/user/getBillingConfig?inventoryType=${inventoryTypeSelected}&make=${current?.substring(0, current.indexOf("-") - 1)}&model=${current?.substring(current.lastIndexOf("-") + 2)}&billingModel=${currentBillingModel}&ServiceProvider=${parseLoginRes?.company}`,
                    )
                        .then((res) => {
                            setCurrentBillingConfig(res?.data);
                            setCurrentAllBillingConfig(res?.data);
                            formik.setFieldValue("billId", res?.data?._id);
                            formik.setFieldValue("totalamount", res?.data?.oneTimeCharge);
                            formik.values.totalamount = res?.data?.oneTimeCharge;

                            if (!additionalsDiscounts.setted) {
                                let arrayofadditional = [];
                                let totalAdditionalPrice = 0;
                                for (let i = 0; i < res?.data?.additionalFeature.length; i++) {
                                    arrayofadditional.push(res?.data?.additionalFeature[i]._id);
                                    totalAdditionalPrice += parseFloat(res?.data?.additionalFeature[i].featureAmount);
                                }
                                let totaldiscountprice = 0;
                                let arrayofdiscounts = [];
                                for (let i = 0; i < res?.data?.selectdiscount.length; i++) {
                                    arrayofdiscounts.push(res?.data?.selectdiscount[i]._id);
                                    totaldiscountprice += parseFloat(res?.data?.selectdiscount[i].amount);
                                }
                                let calclatedAmount = (totalAdditionalPrice + parseFloat(res?.data?.oneTimeCharge) - totaldiscountprice).toFixed(2);
                                formik.setFieldValue("totalamount", calclatedAmount);
                                formik.values.totalamount = calclatedAmount;
                                formik.setFieldValue("additional", arrayofadditional);
                                formik.setFieldValue("discount", arrayofdiscounts);
                                setAdditionalDiscounts((prev) => ({ setted: true, additional: arrayofadditional, discounts: arrayofdiscounts }));
                                setAdditional(res?.data?.additionalFeature);
                                setDiscount(res?.data?.selectdiscount);
                            } else {
                                let totalAdditionalPrice = 0;

                                for (let i = 0; i < res?.data?.additionalFeature.length; i++) {
                                    for (let k = 0; k < additionalsDiscounts?.additional.length; k++) {
                                        if (additionalsDiscounts?.additional[k] === res?.data?.additionalFeature[i]._id) {
                                            totalAdditionalPrice += parseFloat(res?.data?.additionalFeature[i].featureAmount);
                                        }
                                    }
                                }
                                let totaldiscountprice = 0;
                                for (let i = 0; i < res?.data?.selectdiscount.length; i++) {
                                    for (let k = 0; k < additionalsDiscounts?.discounts?.length; k++) {
                                        if (additionalsDiscounts?.discounts[k] === res?.data?.selectdiscount[i]._id) {
                                            totaldiscountprice += parseFloat(res?.data?.selectdiscount[i].amount);
                                        }
                                    }
                                }
                                let calclatedAmount = (totalAdditionalPrice + parseFloat(res?.data?.oneTimeCharge) - totaldiscountprice).toFixed(2);
                                formik.setFieldValue("totalamount", calclatedAmount);
                                formik.values.totalamount = calclatedAmount;
                                formik.setFieldValue("additional", additionalsDiscounts.additional);
                                formik.setFieldValue("discount", additionalsDiscounts.discounts);
                                // setAdditionalDiscounts(prev=>({setted:true,additional:arrayofadditional,discount:arrayofdiscounts}))
                                setAdditional(res?.data?.additionalFeature);
                                setDiscount(res?.data?.selectdiscount);
                            }

                            let paymentobj = [];
                            for (let i = 0; i < res?.data?.paymentMethod?.length; i++) {
                                let obj = { name: res?.data?.paymentMethod[i] };
                                paymentobj.push(obj);
                            }
                            setPaymentMethods(paymentobj);
                            if (currentPlanSelect) {
                                let total = parseFloat(formik.values.totalamount);
                                let planPresent = false;
                                for (let i = 0; i < res1?.data?.length; i++) {
                                    if (currentPlanSelect === res1?.data[i]?._id) {
                                        formik.setFieldValue("totalamount", (total + res1?.data[i]?.price).toFixed(2));
                                        //onPlanSelect(item);
                                        setPreviousPlanPrice(res1?.data[i]?.price);
                                        console.log(res1?.data[i]?.price);
                                        formik.setFieldValue("plan", res1?.data[i]?._id);
                                        setCurrentPlanName(res1?.data[i]?.name);
                                        setCurrentPlanSelect(res1?.data[i]?._id);
                                        formik.setFieldValue("currentPlanSelected", res1?.data[i]?._id);
                                    }
                                }
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => {});
        }
    }, [current]);

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (!checkLabelVal) {
            setCarrierVal("");
            setServicesVal("");
            setDeliveryVal("");
            setChecked("");
            localStorage.removeItem("shipmentName");
            localStorage.removeItem("shipmentCost");
            localStorage.removeItem("checked");
        }
        const dataToSend = {
            customerId: initiationData?._id,
            carrierVal: !checkLabelVal ? "" : carrierVal,
            servicesVal: !checkLabelVal ? "" : servicesVal,
            deliveryVal: !checkLabelVal ? "" : deliveryVal,
            labelSubmissionval: checkLabelVal,
            shippingLabelDiscount: checked,
        };

        Axios.post(`${BASE_URL}/api/user/storeServices`, dataToSend)
            .then((res) => {})
            .catch((err) => {});
    }, [carrierVal, servicesVal, deliveryVal, checkLabelVal, checked]);
    useEffect(() => {
        let cost = 0;
        rates.map((item) => {
            if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                cost = item?.shipmentCost + item?.otherCost;
            }
        });
        let currentamount = parseFloat(formik.values.totalamount);
        currentamount -= previousRate;
        currentamount += cost;
        formik.setFieldValue("totalamount", currentamount);
        setPreviousRate(cost);
    }, [checked, rates]);
    const [makeModelAgainstInventory, setMakeModelAgainstInventory] = useState([]);
    useEffect(() => {
        if (inventoryTypeSelected !== "") {
            setMakeModelAgainstInventory([]);
            setLoader(true);
            Axios.get(`${BASE_URL}/api/inventoryType/getInvetotyByModel?serviceProvider=${parseLoginRes?.company}&inventoryType=${inventoryTypeSelected}&billing=${currentBillingModel}`)
                .then((res) => {
                    setMakeModelAgainstInventory(res?.data?.data);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
    }, [inventoryTypeSelected]);

    return (
        <div className="mb-4">
            <ToastContainer />
            <div style={{ marginBottom: "40px" }} className=" flex flex-wrap flex-row justify-content-around pb-4">
                {/* <div>
                        <h5 className="font-bold text-left">ENROLLMENT ID: {enrollment_id}</h5>
                    </div> */}
                {currentScreen === 1 ? (
                    <div className="paymentmain mt-2 w-full flex flex-wrap flex-row justify-content-start">
                        <h1 style={{ fontSize: "16px", color: "#c68301", width: "100%", textAlign: "center" }} className=" selectProduct mt-6">
                            SELECT PRODUCT
                        </h1>
                        <div className="w-full flex flex-wrap flex-row justify-content-start">
                            <label className="block w-full">Inventory Type</label>
                            <Dropdown
                                placeholder="Select Inventory Type"
                                options={inventory}
                                disabled={paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                optionLabel="label"
                                style={{ width: "400px" }}
                                value={inventoryTypeSelected}
                                className="mt-2"
                                onChange={(e) => {
                                    setInventoryTypeSelected(e.value);
                                }}
                                optionValue="label"
                            />
                        </div>
                        {currentScreen === 1 && !loader && makeModelAgainstInventory.length > 0 ? (
                            makeModelAgainstInventory?.map((item) => {
                                return (
                                    <div style={{ opacity: `${item?.make + " - " + item?.model === current ? "0.5" : ""}` }} className="mt-4 inventory-item inventorySelect">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <RadioButton
                                                style={{ zIndex: "0" }}
                                                type="button"
                                                disabled={
                                                    (item?.make + " - " + item?.model === current) === current ||
                                                    paymentDone ||
                                                    (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)
                                                }
                                                onClick={() => {
                                                    formik.setFieldValue("additional", additionalsDiscounts.additional);
                                                    formik.setFieldValue("discount", additionalsDiscounts.discounts);
                                                    // setAdditionalDiscounts(prev=>({setted:true,additional:arrayofadditional,discount:arrayofdiscounts}))
                                                    setAdditional([]);
                                                    setDiscount([]);
                                                    setInventoryIdSelected(item?._id);

                                                    formik.setFieldValue("inventorySelected", item?.make + " - " + item?.model);
                                                    formik.setFieldValue("plan", "");
                                                    setCurrentPlanName("");
                                                    setCurrentPlanSelect("");
                                                    formik.setFieldValue("currentPlanSelected", "");
                                                    formik.setFieldValue("additional", []);
                                                    formik.setFieldValue("discount", []);
                                                    setAdditionalDiscounts((prev) => ({ discounts: [], additional: [], setted: false }));
                                                    formik.setFieldValue("totalamount", "");
                                                    setPreviousPlanPrice(0);
                                                    formik.setFieldValue("plan", "");
                                                    setCurrentSelect(item?.make + " - " + item?.model);
                                                }}
                                            />
                                            <h1 className="w-full h4" style={{ marginLeft: "-20px", textTransform: "uppercase" }}>
                                                {capitalizeSentence(item?.make + " - " + item?.model)}
                                            </h1>
                                        </div>
                                        <div className="image-cover">
                                            <img className="simimg" src={`${BASE_URL}/inventory/${item?.image}`} />
                                        </div>
                                        {/* <button
                type="button"
                disabled={item?.label === current || paymentDone}
                onClick={() => {
                    onInventorySelect(item);
                }}
            >
                Select
            </button> */}
                                    </div>
                                );
                            })
                        ) : loader ? (
                            <div className="w-full">
                                <div style={{ marginTop: "200px" }} className="mt-4 flex justify-content-center">
                                    <CommonLoaderBlue />
                                </div>
                            </div>
                        ) : inventoryTypeSelected !== "" ? (
                            <p className="mt-4 p-error">Billing Configuration Not Setup For This Inventory Type </p>
                        ) : undefined}
                    </div>
                ) : currentScreen === 1 ? (
                    <div>
                        <div style={{ marginTop: "200px" }} className="mt-4 flex justify-content-center">
                            <CommonLoaderBlue />
                        </div>
                    </div>
                ) : undefined}
                {currentScreen === 2 ? (
                    <div style={{ marginTop: "3.5rem" }} className=" w-full flex flex-wrap flex-row justify-content-left">
                        <h1 className="w-full selectplan "> SELECT PLAN</h1>
                        {plan?.map((item) => {
                            return (
                                <>
                                    <div className="planSelect " style={{ height: "auto", paddingBottom: "20px", opacity: `${item._id === currentPlanSelect ? "0.5" : ""} ` }}>
                                        <div className="planinfo">
                                            <h1 style={{ marginLeft: "15px", marginTop: "10px" }}>{item?.name?.toUpperCase()}</h1>
                                            <h1 style={{ marginLeft: "15px" }}>No Hidden Fees / No Contracts</h1>
                                            <h1 style={{ marginLeft: "15px" }} className="planprice">
                                                <span style={{ fontFamily: "Inter", fontSize: "38px", fontWeight: "600", marginTop: "1px" }}>$</span>
                                                <span style={{ marginLeft: "36px" }}>{item?.price}</span>
                                                <span style={{ fontWeight: "600", fontFamily: "Inter", fontSize: "18px", marginLeft: "10px" }}>Monthly</span>
                                            </h1>
                                            <button
                                                type="button"
                                                disabled={item?._id === currentPlanSelect || paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                                onClick={() => {
                                                    let total = parseFloat(formik.values.totalamount);
                                                    if (previousplanprice !== 0) {
                                                        total = total - previousplanprice;
                                                    }
                                                    formik.setFieldValue("totalamount", (total + item?.price).toFixed(2));
                                                    //onPlanSelect(item);
                                                    setPreviousPlanPrice(item?.price);
                                                    formik.setFieldValue("plan", item?._id);
                                                    setCurrentPlanName(item?.name);
                                                    setCurrentPlanSelect(item?._id);
                                                    formik.setFieldValue("currentPlanSelected", item?._id);
                                                    Axios.post(`${BASE_URL}/api/user/plan`, { csr: parseLoginRes?._id, customerId: parentDetails._id, plan: item?._id, inventoryId: inventoryIdSelected, carrier: item?.carrier?._id })
                                                        .then(() => {})
                                                        .catch((err) => {});
                                                }}
                                            >
                                                GET STARTED
                                            </button>
                                        </div>

                                        <p className="voiceallowance">
                                            <Tickbtn />
                                            <span style={{ marginLeft: "6px" }}>
                                                Voice Allowance {item?.voiceAllowance} <span>MINUTES</span>
                                            </span>
                                        </p>
                                        <p className="dataallowance">
                                            <Tickbtn />
                                            <span style={{ marginLeft: "10px" }}>
                                                Data Allowance {item?.dataAllowance} <span>MB</span>
                                            </span>
                                        </p>
                                        <p className="textallowance">
                                            <Tickbtn />
                                            <span style={{ marginLeft: "10px" }}>
                                                Text Allowance {item?.textAllowance} <span>SMS</span>
                                            </span>
                                        </p>
                                        <p className="textallowance">
                                            <Tickbtn />
                                            <span style={{ marginLeft: "10px" }}>{item?.carrier?.name}</span>
                                        </p>
                                        {item?.planFeature.map((featureItem, index) => {
                                            return featureItem?.isSelected ? (
                                                <p key={index} className="textallowance">
                                                    <Tickbtn />
                                                    <span style={{ marginLeft: "10px" }}>{featureItem?.name}</span>
                                                </p>
                                            ) : (
                                                <p key={index} className="textallowance">
                                                    <i className="pi unselected  text-red-500 pi-times" />

                                                    <span style={{ marginLeft: "10px" }}>{featureItem?.name}</span>
                                                </p>
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                ) : undefined}
                {currentScreen === 3 ? (
                    <div className="flex flex-wrap w-full justify-content-left">
                        <div className="flex flex-wrap w-full calendar_field">
                            <Checkbox
                                checked={checkLabelVal}
                                disabled={paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                onChange={(e) => {
                                    setCheckLabelVal(e.checked);
                                    setGenerate(e.checked);

                                    localStorage.setItem("generatelabel", generate);

                                    setProspectWithInvoice(false);
                                    setProspectWithOutInvoice(false);
                                    formik.setFieldValue("prospectwithinvoice", false);
                                    setProspectCases({ prospectWithInvoice: false, propectWithOutInvoice: false });
                                    formik.setFieldValue("prospectwithoutinvoice", false);
                                }}
                            />
                            <p className="filed_label">&nbsp;&nbsp;Generate Label on Submission</p>
                        </div>
                        <div className="calendar_field">
                            <p className="field_label">
                                Carrier <span style={{ color: "red" }}>*</span>
                            </p>
                            <Dropdown
                                disabled={!checkLabelVal || paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                value={carrierVal}
                                id="name"
                                onChange={(e) => {
                                    setCarrierVal(e.value);
                                    setExpectedCost(false);
                                }}
                                options={carrier}
                                optionLabel="name"
                                placeholder="Select Carrier"
                                optionValue="code"
                                className="w-full "
                            />
                        </div>
                        <div className="calendar_field">
                            <p className="field_label ml-2">
                                Services <span style={{ color: "red" }}>*</span>
                            </p>

                            <Dropdown
                                value={servicesVal}
                                id="name"
                                disabled={!checkLabelVal || paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                onChange={(e) => {
                                    setServicesVal(e.value);
                                    setExpectedCost(false);
                                }}
                                options={services}
                                optionLabel="name"
                                optionValue="code"
                                placeholder="Select Service"
                                className="w-full ml-2"
                            />
                        </div>
                        <div className="calendar_field">
                            <p className="field_label ,l-3">
                                Delivery Confirmation <span style={{ color: "red" }}>*</span>
                            </p>

                            {carrierVal === "FedEx" ? (
                                <Dropdown
                                    disabled={!checkLabelVal || paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                    value={deliveryVal}
                                    onChange={(e) => {
                                        setDeliveryVal(e.value);
                                        setExpectedCost(false);
                                    }}
                                    options={deliveryFedEx}
                                    optionLabel="name"
                                    optionValue="name"
                                    placeholder="Select Delivery Option"
                                    className="w-full ml-3 "
                                />
                            ) : (
                                <Dropdown
                                    disabled={!checkLabelVal || paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                    value={deliveryVal}
                                    onChange={(e) => {
                                        setDeliveryVal(e.value);
                                        setExpectedCost(false);
                                    }}
                                    options={delivery}
                                    optionLabel="type"
                                    optionValue="type"
                                    placeholder="Select Delivery Option"
                                    className="w-full ml-3"
                                />
                            )}
                        </div>
                        <div className="calendar_field">
                            <p className="field_label mt-4">
                                Expected Cost :
                                <span>
                                    {ratesLoading ? (
                                        <span>
                                            &nbsp; <i className="pi pi-spin pi-spinner" style={{ color: "black" }}></i>
                                        </span>
                                    ) : (
                                        rates?.map((item) => {
                                            try {
                                                if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                                                    let rate = item?.shipmentCost + item?.otherCost;
                                                    rate = rate?.toFixed(2);
                                                    localStorage.setItem("shipmentCost", rate);
                                                    localStorage.setItem("shipmentName", item?.serviceName);

                                                    return <span key={item._id}> $ {rate}</span>;
                                                }
                                            } catch (e) {}
                                        })
                                    )}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-wrap w-full calendar_field">
                            <Checkbox
                                disabled={!checkLabelVal || paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                checked={checked}
                                onChange={(e) => {
                                    setChecked(e.checked);
                                    localStorage.setItem("checked", e.checked);
                                }}
                            />
                            <p style={{ opacity: !checkLabelVal ? "0.5" : "" }} className="filed_label">
                                &nbsp;&nbsp;Add Shipping Label as Discount
                            </p>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {currentScreen === 4 ? (
                    <div className="w-full flex flex-wrap flex-row justify-content-left prospdiv">
                        <div className="flex w-full  linkedprospect flex-wrap flex-row justify-content-left  mt-6 ">
                            <p
                                onClick={() => {
                                    if (!checkLabelVal && !paymentDone && !(isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)) {
                                        formik.setFieldValue("paymentMode", "");
                                        formik.setFieldValue("prospectwithoutinvoice", false);
                                        setpaymentmethoderror(false);
                                        setProspectWithOutInvoice(false);
                                        if (propectWithInvoice) {
                                            setProspectWithInvoice(false);

                                            formik.setFieldValue("prospectwithinvoice", false);

                                            setProspectCases({ prospectWithoutInvoice: false, prospectWithInvoice: false });
                                        } else {
                                            setProspectWithInvoice(true);
                                            formik.setFieldValue("paid", "");
                                            formik.setFieldValue("prospectwithinvoice", true);

                                            setProspectCases({ prospectWithoutInvoice: false, prospectWithInvoice: true });
                                        }
                                    }
                                }}
                                style={{
                                    border: `${formik.values.prospectwithinvoice ? "1px solid #c68301" : "1px solid #0475b4"}`,
                                    color: `${formik.values.prospectwithinvoice ? "white" : "#0475b4"}`,
                                    backgroundColor: `${formik.values.prospectwithinvoice ? "#c68301" : "white"}`,
                                    borderRadius: "8px",
                                    opacity: `${checkLabelVal ? "0.6" : "1"}`,
                                    padding: "6px",
                                    height: "35px",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    fontFamily: "Inter",
                                }}
                            >
                                Save As Prospect With Invoice
                            </p>

                            <p
                                onClick={() => {
                                    if (!checkLabelVal && !paymentDone && !(isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)) {
                                        formik.setFieldValue("paymentMode", "");
                                        setpaymentmethoderror(false);

                                        formik.setFieldValue("prospectwithinvoice", false);
                                        setProspectWithInvoice(false);
                                        if (propectWithOutInvoice) {
                                            setProspectWithOutInvoice(false);

                                            formik.setFieldValue("prospectwithoutinvoice", false);
                                            setProspectCases({ prospectWithoutInvoice: false, prospectWithInvoice: false });
                                        } else {
                                            setProspectWithOutInvoice(true);
                                            formik.setFieldValue("paid", "");
                                            formik.setFieldValue("prospectwithoutinvoice", true);

                                            setProspectCases({ prospectWithoutInvoice: true, prospectWithInvoice: false });
                                        }
                                    }
                                }}
                                style={{
                                    border: `${formik.values.prospectwithoutinvoice ? "1px solid #c68301" : "1px solid #0475b4"}`,
                                    opacity: `${checkLabelVal ? "0.6" : "1"}`,
                                    color: `${formik.values.prospectwithoutinvoice ? "white" : "#0475b4"}`,
                                    backgroundColor: `${formik.values.prospectwithoutinvoice ? "#c68301" : "white"}`,
                                    borderRadius: "8px",
                                    marginLeft: "6px",
                                    fontWeight: "600",
                                    padding: "6px",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    height: "35px",
                                    fontFamily: "Inter",
                                }}
                            >
                                {" "}
                                Save As Prospect WithOut Invoice
                            </p>
                        </div>

                        <div className="mt-2  calendar_field">
                            <label className="field_label">Select Additional Feature</label>

                            <MultiSelect
                                disabled={paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                className="w-full mt-2  discountmultiselect .p-multiselect-trigger "
                                id="additional"
                                placeholder="Select Additional Feature"
                                optionLabel="featureName"
                                options={additional}
                                optionValue="_id"
                                value={formik.values.additional}
                                onChange={(e) => {
                                    let previousadditional = formik.values.additional;
                                    let previousadditionaltotal = 0;
                                    let newadditionaltotal = 0;
                                    for (let i = 0; i < currentBillingConfig?.additionalFeature.length; i++) {
                                        if (previousadditional.includes(currentBillingConfig?.additionalFeature[i]._id)) {
                                            previousadditionaltotal += parseFloat(currentBillingConfig?.additionalFeature[i]?.featureAmount);
                                        }
                                    }
                                    for (let i = 0; i < currentBillingConfig?.additionalFeature.length; i++) {
                                        if (e.value.includes(currentBillingConfig?.additionalFeature[i]._id)) {
                                            newadditionaltotal += parseFloat(currentBillingConfig?.additionalFeature[i]?.featureAmount);
                                        }
                                    }
                                    let totalamount = parseFloat(formik.values.totalamount);
                                    totalamount = totalamount - previousadditionaltotal + newadditionaltotal;
                                    formik.setFieldValue("totalamount", totalamount.toFixed(2));
                                    formik.setFieldValue("additional", e.value);
                                    setAdditionalDiscounts((prev) => ({ ...prev, additional: e.value }));
                                }}
                            />
                            {getFormErrorMessage("additional")}
                        </div>

                        <div style={{ marginLeft: "4px" }} className="mt-2  calendar_field">
                            <label className="field_label">Select Discounts</label>

                            <MultiSelect
                                disabled={paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                className="w-full mt-2 discountmultiselect .p-multiselect-trigger"
                                id="discount"
                                placeholder="Select Discounts"
                                optionLabel="discountname"
                                optionValue="_id"
                                options={discount}
                                value={formik.values.discount}
                                onChange={(e) => {
                                    let previousdiscount = formik.values.discount;
                                    let previousdiscounttotal = 0;
                                    let newdiscounttotal = 0;
                                    for (let i = 0; i < currentBillingConfig?.selectdiscount.length; i++) {
                                        if (previousdiscount.includes(currentBillingConfig?.selectdiscount[i]._id)) {
                                            previousdiscounttotal += parseFloat(currentBillingConfig?.selectdiscount[i]?.amount);
                                        }
                                    }
                                    for (let i = 0; i < currentBillingConfig?.selectdiscount.length; i++) {
                                        if (e.value.includes(currentBillingConfig?.selectdiscount[i]._id)) {
                                            newdiscounttotal += parseFloat(currentBillingConfig?.selectdiscount[i]?.amount);
                                        }
                                    }
                                    let totalamount = parseFloat(formik.values.totalamount);
                                    totalamount = totalamount + previousdiscounttotal - newdiscounttotal;
                                    formik.setFieldValue("totalamount", totalamount.toFixed(2));
                                    formik.setFieldValue("discount", e.value);
                                    setAdditionalDiscounts((prev) => ({ ...prev, discounts: e.value }));
                                }}
                            />
                            {getFormErrorMessage("discount")}
                        </div>
                        <div style={{ marginLeft: "4px" }} className="mt-2 calendar_field">
                            <label className="field_label">Net Amount</label>
                            <InputText
                                disabled
                                className="w-full mt-2"
                                id="totalamount"
                                value={formik.values.totalamount}
                                onChange={(e) => {
                                    formik.setFieldValue("totalpayment", e.value.toFixed(2));
                                    formik.handleChange(e);
                                }}
                            />
                            {getFormErrorMessage("totalpayment")}
                        </div>

                        {formik.values.paymentMode === "Credit Card" && !parseLoginRes?.companyName?.toUpperCase()?.includes("TALKDAILY") ? (
                            <div style={{ marginLeft: "4px" }} className="mt-6 calendar_field ">
                                <label className="field_label">Paying Amount</label>
                                <InputText
                                    disabled={paymentDone || (isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0)}
                                    className="w-full mt-2"
                                    id="paid"
                                    value={formik.values.paid}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setPaidAmountRequired(true);
                                        } else {
                                            setPaidAmountRequired(false);
                                        }
                                        formik.setFieldValue("paid", e.target.value);
                                        setPayingAmount(e.target.value);
                                        // formik.handleChange(e);
                                    }}
                                />
                                {paidAmountRequired ? <p className="p-error mt-1 ml-1">Paying Amount Is Required</p> : ""}
                                {getFormErrorMessage("paid")}
                            </div>
                        ) : (
                            ""
                        )}
                        <div style={{ marginLeft: "4px" }} className="mt-6 calendar_field">
                            <label className="field_label">Select Payment Method</label>
                            <Dropdown
                                disabled={
                                    paymentDone
                                        ? true
                                        : formik.values.prospectwithinvoice || formik.values.prospectwithoutinvoice
                                          ? true
                                          : isPrepaidIncomplete && isPrepaidIncomplete !== "" && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration).length > 0
                                }
                                className="w-full mt-2 p-dropdown .p-dropdown-trigger"
                                id="paymentMode"
                                optionValue="name"
                                optionLabel="name"
                                options={paymentMethods}
                                value={formik.values.paymentMode}
                                onChange={(e) => {
                                    formik.setFieldValue("paymentMode", e.value);
                                    formik.handleChange(e);
                                    setpaymentmethoderror(false);
                                    setProspectWithOutInvoice(false);
                                    formik.setFieldValue("prospectwithoutinvoice", false);
                                    formik.setFieldValue("prospectwithinvoice", false);
                                    setProspectWithInvoice(false);
                                }}
                            />
                            {paymentmethoderror && <p className="p-error">Payment Method Is Required</p>}
                        </div>
                    </div>
                ) : undefined}

                <>
                    <Dialog style={{ textAlign: "center" }} className="stripe-dialog-width" header="Stripe Payment" visible={paymentDialogVisibility} setPaymentDialogVisibility={setPaymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                        <Payment
                            setAllPaymentInfo={setAllPaymentInfo}
                            parentDetails={parentDetails}
                            setAllpaymentDone={setAllpaymentDone}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenActive={setPaymentScreenActive}
                            setDataSendForInvoice={setDataSendForInvoice}
                            paymentScreenDetails={paymentScreenDetails}
                            currentBillingConfig={currentBillingConfig}
                            paid={formik.values.paid}
                            plan={formik.values.plan}
                            paymentDone={paymentDone}
                            setPaymentDone={setPaymentDone}
                            setPaymentScreenDetails={setPaymentScreenDetails}
                            setPaymentDialogVisibility={setPaymentDialogVisibility}
                            amount={formik.values.totalamount}
                            initiationData={initiationData}
                            object={formik.values}
                        />
                    </Dialog>
                </>
            </div>
            <Dialog className="stripe-dialog-width" header="PayPal Card Payment" visible={dialogForCardAuth} onHide={() => setdialogForCardAuth(false)}>
                <CardAuthPayment
                    initiationData={initiationData}
                    setAllPaymentInfo={setAllPaymentInfo}
                    parentDetails={parentDetails}
                    setAllpaymentDone={setAllpaymentDone}
                    setPreviewScreenActive={setPreviewScreenActive}
                    setPaymentScreenActive={setPaymentScreenActive}
                    setDataSendForInvoice={setDataSendForInvoice}
                    paymentScreenDetails={paymentScreenDetails}
                    currentBillingConfig={currentBillingConfig}
                    paid={formik.values.paid}
                    allplans={plan}
                    toast={toast}
                    plan={formik.values.plan}
                    paymentDone={paymentDone}
                    setPaymentDone={setPaymentDone}
                    setPaymentScreenDetails={setPaymentScreenDetails}
                    setPaymentDialogVisibility={setPaymentDialogVisibility}
                    amount={formik.values.totalamount}
                    currentBillingModel={currentBillingModel}
                    object={formik.values}
                />
            </Dialog>
            <Dialog className="stripe-dialog-width" header="Authorize E-Check Payment" visible={dialogForEcheck} onHide={() => setDialogForEcheck(false)}>
                <EcheckAuthPayment
                    initiationData={initiationData}
                    setAllPaymentInfo={setAllPaymentInfo}
                    parentDetails={parentDetails}
                    setAllpaymentDone={setAllpaymentDone}
                    allplans={plan}
                    setPreviewScreenActive={setPreviewScreenActive}
                    setPaymentScreenActive={setPaymentScreenActive}
                    setDataSendForInvoice={setDataSendForInvoice}
                    paymentScreenDetails={paymentScreenDetails}
                    currentBillingConfig={currentBillingConfig}
                    paid={formik.values.paid}
                    plan={formik.values.plan}
                    paymentDone={paymentDone}
                    setPaymentDone={setPaymentDone}
                    setPaymentScreenDetails={setPaymentScreenDetails}
                    setPaymentDialogVisibility={setPaymentDialogVisibility}
                    amount={formik.values.totalamount}
                    currentBillingModel={currentBillingModel}
                    object={formik.values}
                />
            </Dialog>
            <div className="mt-4">
                <div className="flex flex-row justify-content-end w-full  align-items-center mb-2 sticky-buttons ">
                    {/* <div style={{ marginLeft: "80%", marginTop: "2rem" }}>
                        <Button className="btn" label="Back" type="button" onClick={handleBack} />
                    </div> */}
                    <div className="flex flex-wrap flex-row justify-content-left">
                        <Button
                            className="btn "
                            label="Back"
                            type="button"
                            onClick={() => {
                                if (currentScreen > 1) {
                                    setCurrentScreen((prev) => (prev = prev - 1));
                                } else {
                                    setConfirmationActive(true);
                                    setPaymentScreenActive(false);
                                }
                            }}
                        />
                        <Button
                            className="btn ml-1"
                            label="Continue"
                            type="button"
                            onClick={() => {
                                if (currentScreen !== 4) {
                                    if (currentScreen === 3) {
                                        if (!checked && !checkLabelVal) {
                                            let currentamount = parseFloat(formik.values.totalamount);
                                            currentamount -= previousRate;
                                            setPreviousRate(0);
                                            formik.setFieldValue("totalamount", currentamount);
                                        } else if (checked && checkLabelVal) {
                                            let currentamount = parseFloat(formik.values.totalamount);
                                            currentamount -= previousRate;
                                            setPreviousRate(0);
                                            formik.setFieldValue("totalamount", currentamount);
                                        }
                                    }
                                    if (currentScreen === 1) {
                                        setIsLoading(true);
                                        Axios.put(`${BASE_URL}/api/user/storeInventory`, {
                                            inventoryId: inventoryIdSelected,
                                            serviceProvider: parseLoginRes?.company,
                                            customerId: initiationData?._id,
                                        })
                                            .then((response) => {
                                                setIsLoading(false);
                                                setCurrentScreen((prev) => (prev = prev + 1));
                                            })
                                            .catch((err) => {
                                                setIsLoading(false);
                                            });
                                    } else {
                                        setCurrentScreen((prev) => (prev = prev + 1));
                                    }
                                } else {
                                    setIsLoading(true);
                                    Axios.put(`${BASE_URL}/api/user/saveData`, {
                                        serviceProvider: parseLoginRes?.company,
                                        customerId: initiationData?._id,
                                        netAmount: formik.values.totalamount,
                                        isProspect: formik.values.prospectwithinvoice,
                                        isWithoutProspect: formik.values.prospectwithoutinvoice,

                                        payingAmount: formik.values.paid,
                                        paymentMethod: formik.values.paymentMode,
                                        additionalCharges: formik.values.additional,
                                        discount: formik.values.discount,
                                    })
                                        .then((response) => {
                                            setIsLoading(false);
                                            if (isPrepaidIncomplete && isPrepaidIncomplete?.activeBillingConfiguration && Object.keys(isPrepaidIncomplete?.activeBillingConfiguration.length > 0)) {
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true);
                                            } else {
                                                formik.handleSubmit();
                                            }
                                        })
                                        .catch((err) => {
                                            setIsLoading(false);
                                        });
                                }
                            }}
                            icon={isLoading ? "pi pi-spin pi-spinner " : ""}
                            disabled={
                                isLoading ||
                                (currentScreen === 1 && current === "") ||
                                (currentScreen === 2 && currentPlanSelect === "") ||
                                (currentScreen === 3 && checkLabelVal && ((carrierVal === "" && checkLabelVal) || !expectedCost || (servicesVal === "" && checkLabelVal) || !expectedCost || (deliveryVal === "" && checkLabelVal) || !expectedCost))
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
