import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import DialogForReject from "../PostPaid-Dialoge/DialogeFor_Reject";
import DialogForActivateSim from "../PostPaid-Dialoge/DialogeFor_Activate";
import { InputText } from "primereact/inputtext";
import { PrimeIcons } from "primereact/api";
import DialogeForRemarks from "../PostPaid-Dialoge/DialogeFor_Remarks";
import DialogeForTransferUser from "../PostPaid-Dialoge/DialogeFor_TransferUser";
import DialogeForRemarksForIJ from "../PostPaid-Dialoge/DialogeFor_RemaeksForIJ";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const All_Enrollments = () => {
    const [cpData, setCpData] = useState([]);
    const [selectedEnrollmentId, setSelectedEnrollmentId] = useState();
    const [isEnrolmentId, setIsEnrolmentId] = useState();
    const [CsrId, setCsrId] = useState();
    const [zipCode, setZipCode] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDialogeForActivate, setOpenDialogeForActivate] = useState(false);
    const [OpenDialogeForRemarks, setOpenDialogeForRemarks] = useState(false);
    const [OpenDialogeForRemarksForIJ, setOpenDialogeForRemarksForIJ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setisButtonLoading] = useState(false);
    const [link, setLink] = useState();
    const [allEnrollments, setAllEnrollments] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [createDateToFilterValue, setCreatedDateToFilterValue] = useState("");
    const [checkType, setCheckType] = useState();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enrollment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdFilter: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [enrollmentIdFilterValue, setEnrollmentIdFilterValue] = useState("");
    const [createDateFilterValue, setCreatedDateFilterValue] = useState("");
    const [dates, setDates] = useState(null);
    const [filteredDates, setFilteredDates] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dialogeForTransfer, setDialogeForTransfer] = useState(false);
    const [dialogeForApprove, setDialogeForApprove] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [checkRemarks, setCheckRemarks] = useState();
    const [selectedIdsForApprove, setSelectedIdsForApprove] = useState();

    const navigate = useNavigate();

    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    // Get role name  from login response
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;

    const roleName = parseLoginRes?.role?.role;
    const toCapital = roleName.toUpperCase();

    const onGlobalFilterValueChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onNameDateEnrollmentIdValueFilter = (e, field) => {
        // const value = e.target.value;
        const value = e.target.value;
        let _filters = { ...filters };
        if (field === "enrollment") {
            _filters["enrollment"].value = value;
            setFilters(_filters);
            setEnrollmentIdFilterValue(value);
        } else if (field === "createdTo") {
            const parsedDate = DateTime.fromJSDate(new Date(e.value));
            let easternDateTime = parsedDate.setZone("America/New_York", { keepLocalTime: true });
            easternDateTime = easternDateTime.set({
                hour: 23,
                minute: 59,
                second: 0,
            });

            const formattedEasternTime = easternDateTime.toFormat("d LLL yyyy, h:mm a");
            const etDateObject = DateTime.fromFormat(formattedEasternTime, "d LLL yyyy, h:mm a", { zone: "America/New_York" });
            const value2 = etDateObject.toSeconds();
            setCreatedDateToFilterValue(e.value);
            _filters["createdTo"].value = value2;
            setFilters(_filters);
        } else {
            const parsedDate = DateTime.fromJSDate(new Date(e.value));
            const easternDateTime = parsedDate.setZone("America/New_York", { keepLocalTime: true });
            const formattedEasternTime = easternDateTime.toFormat("d LLL yyyy, h:mm a");
            const etDateObject = DateTime.fromFormat(formattedEasternTime, "d LLL yyyy, h:mm a", { zone: "America/New_York" });
            const value = etDateObject.toSeconds();

            setCreatedDateFilterValue(e.value);
            _filters["createdFilter"].value = value;
            setFilters(_filters);
        }
    };

    const getAllEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await Axios.get(`${BASE_URL}/api/user/EnrollmentApprovedByUser?userId=${parseLoginRes?._id}&accountType=Postpaid`);
            if (res?.status === 200 || res?.status === 201) {
                /* if (!(res?.data?.data)) {
                    toast.success(" No enrollments have been received from the previous department yet");
                } else */
                if (res?.data?.data) {
                    const updatedData = res?.data?.data.map((item) => ({
                        ...item,
                        enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                        name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                        createdDate: new Date(item.createdAt)
                            .toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                            })
                            .replace(/\//g, "-"),
                        createdFilter: DateTime.fromFormat(item.createdAt, "d LLL yyyy, h:mm a", { zone: "America/New_York" }).toSeconds(),
                        createdTo: DateTime.fromFormat(item.createdAt, "d LLL yyyy, h:mm a", { zone: "America/New_York" }).toSeconds(),
                    }));

                    setAllEnrollments(updatedData);
                }
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllEnrollments();
    }, []);

    const viewRow = async (rowData) => {
        setisButtonLoading(true);
        const _id = rowData._id;
        const type = rowData?.enrollment;

        if (type.includes("Self")) {
            setSelectedEnrollmentId(_id);
            try {
                const response = await Axios.get(`${BASE_URL}/api/user/userDetails?userId=${_id}`);
                if (response?.status === 201 || response?.status === 200) {
                    localStorage.removeItem("zip");
                    localStorage.removeItem("fromRejected");
                    localStorage.setItem("initialInformation", JSON.stringify(response.data));
                    localStorage.setItem("homeAddress", JSON.stringify(response.data));
                    localStorage.setItem("selectProgram", JSON.stringify(response.data));
                    navigate("/personalinfo");
                    setisButtonLoading(false);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
        } else {
            setSelectedEnrollmentId(_id);
            try {
                const response = await Axios.get(`${BASE_URL}/api/user/userDetails?userId=${_id}`);
                if (response?.status === 201 || response?.status === 200) {
                    localStorage.removeItem("zipData"); // Use removeItem instead of clearItem
                    localStorage.setItem("basicData", JSON.stringify(response.data));
                    localStorage.setItem("address", JSON.stringify(response.data));
                    localStorage.setItem("programmeId", JSON.stringify(response.data));
                    localStorage.setItem("productData", JSON.stringify(response.data?.data));
                    let storedData = JSON.parse(localStorage.getItem("fromIncomplete")) || {};
                    if (storedData) {
                        storedData = false;
                        localStorage.setItem("fromIncomplete", JSON.stringify(storedData));
                    } else {
                        storedData = false;
                        localStorage.setItem("fromIncomplete", JSON.stringify(storedData));
                    }
                    navigate("/post-enrollment");
                    setisButtonLoading(false);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
        }

        setisButtonLoading(false);
    };

    const approveRow = async (rowData) => {
        setisButtonLoading(true);
        const approvedBy = parseLoginRes?._id;
        const enrolmentId = rowData?._id;
        const approved = true;
        const dataToSend = { approvedBy, enrolmentId, approved };

        try {
            const response = await Axios.patch(`${BASE_URL}/api/user/prePostapproval`, dataToSend);
            if (response?.status === 201 || response?.status === 200) {
                toast.success("Approved");
                setisButtonLoading(false);
                getAllEnrollments();

                const dataToSend = {
                    orderNumber: rowData?.enrollmentId,
                };
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/order`, dataToSend);
                    if (response?.status == 200 || response?.status == 201) {
                        const orderId = response?.data?.data?.orderId;

                        const dataToSend = {
                            userId: parseLoginRes?._id,
                            testLabel: "false",
                            orderId: orderId,
                        };
                        try {
                            const response = await Axios.post(`${BASE_URL}/api/web/order/createLable`, dataToSend);
                            if (response?.status == 200 || response?.status == 201) {
                                if (response?.status == 200 || response?.status == 201) {
                                    const getCustomerProfileData = async () => {
                                        try {
                                            const res = await Axios.get(`${BASE_URL}/api/user/getpostpaidpayment?customerId=${enrolmentId}`);
                                            if (res?.status == 200 || res?.status == 201) {
                                                setCpData(res?.data?.paymentDetails || []);
                                               
                                                const dataToSend = {
                                                    customerId: enrolmentId,
                                                    invoiceType: "Sign Up",
                                                    totalAmount: res?.data?.paymentDetails?.totalAmount,
                                                    additionalCharges: res?.data?.paymentDetails?.additionalCharges,
                                                    discount: res?.data?.paymentDetails?.discount,
                                                    paymentChannel: res?.data?.paymentDetails?.paymentChannel,
                                                    paymentId: res?.data?.paymentDetails?.paymentId,
                                                    amountPaid: "0",
                                                    invoiceDueDate: res?.data?.paymentDetails?.invoiceDueDate,
                                                    lateFee: res?.data?.paymentDetails?.lateFee,
                                                    invoiceOneTimeCharges: res?.data?.paymentDetails?.invoiceOneTimeCharges,
                                                    invoiceStatus: "Pending",
                                                    planId: res?.data?.paymentDetails?.planId,
                                                    planName: res?.data?.paymentDetails?.planName,
                                                    planCharges: res?.data?.paymentDetails?.planCharges,
                                                    chargingType: "monthly",
                                                    invoicePaymentMethod: "Skip",
                                                    printSetting: "Both",  
                                                    userId:parseLoginRes?._id,
                                                    billingPeriod: {
                                                        from: "onActivation",
                                                        to: "onActivation",
                                                    },
                                                };

                                                try {
                                                    const response = await Axios.post(`${BASE_URL}/api/web/invoices/generateInvoice`, dataToSend);
                                                    if (response?.status == 200 || response?.status == 201) {
                                                        toast.success("Invoice Generated");
                                                    }
                                                } catch (error) {
                                                    toast.error(error?.response?.data?.message);
                                                }
                                            }
                                        } catch (error) {}
                                    };

                                    getCustomerProfileData();
                                }
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.msg);
                        }
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setisButtonLoading(false);
        }
        getAllEnrollments();
    };

    const approveRowByTl = async (rowData) => {
        setisButtonLoading(true);
        const approvedBy = parseLoginRes?._id;
        const enrolmentId = rowData?._id;
        const approved = true;
        const dataToSend = { approvedBy, enrolmentId, approved };

        setCheckRemarks(rowData?.QualityRemarks);

        if (rowData?.QualityRemarks) {
            if (rowData && rowData.QualityRemarks && rowData.QualityRemarks.includes("declined")) {
                toast.error("Declined sales can only rejected");
                setisButtonLoading(false);
            } else {
                try {
                    const response = await Axios.patch(`${BASE_URL}/api/user/prePostapproval`, dataToSend);
                    if (response?.status === 201 || response?.status === 200) {
                        getAllEnrollments();

                        const dataToSend = {
                            orderNumber: rowData?.enrollmentId,
                        };
                        try {
                            const response = await Axios.post(`${BASE_URL}/api/web/order`, dataToSend);
                            if (response?.status == 200 || response?.status == 201) {
                                const orderId = response?.data?.data?.orderId;

                                const dataToSend = {
                                    userId: parseLoginRes?._id,
                                    testLabel: "false",
                                    orderId: orderId,
                                };
                                try {
                                    const response = await Axios.post(`${BASE_URL}/api/web/order/createLable`, dataToSend);
                                    if (response?.status == 200 || response?.status == 201) {
                                        const getCustomerProfileData = async () => {
                                            try {
                                                const res = await Axios.get(`${BASE_URL}/api/user/getpostpaidpayment?customerId=${enrolmentId}`);
                                                if (res?.status == 200 || res?.status == 201) {
                                                    setCpData(res?.data?.paymentDetails || []);

                                                    // Move the following code inside this try block

                                                    const dataToSend = {
                                                        customerId: enrolmentId,
                                                        planId: res?.data?.paymentDetails?.planId,
                                                        invoiceType: "Sign Up",  
                                                        shipmentCost:res?.data?.paymentDetails?.shipmentCost, 
                                                        isShipmentCostDiscounted:res?.data?.paymentDetails?.isShipmentCostDiscounted,
                                                        totalAmount: res?.data?.paymentDetails?.totalAmount,
                                                        amountPaid: res?.data?.paymentDetails?.amountPaid,
                                                        invoiceStatus: res?.data?.paymentDetails?.invoiceStatus,
                                                        stripeId: res?.data?.paymentDetails?.paymentId,
                                                        paymentChannel: res?.data?.paymentDetails?.paymentChannel,
                                                        chargingType: "monthly",
                                                        invoicePaymentMethod: res?.data?.paymentDetails?.paymentMethod,
                                                        printSetting: "Both",    
                                                        userId:parseLoginRes?._id,
                                                        billingPeriod: {
                                                            from: "onActivation",
                                                            to: "onActivation",
                                                        },
                                                    };

                                                    try {
                                                        const response = await Axios.post(`${BASE_URL}/api/web/invoices/generateInvoice`, dataToSend);
                                                        if (response?.status == 200 || response?.status == 201) {
                                                            setisButtonLoading(false);
                                                            toast.success("Approved and Invoice Generated");
                                                        }
                                                    } catch (error) {
                                                        toast.error("Approved but Invoice is not generate");
                                                        toast.error(error?.response?.data?.message);
                                                    }
                                                }
                                            } catch (error) {}
                                        };

                                        getCustomerProfileData();
                                    }
                                } catch (error) {
                                    toast.error(error?.response?.data?.msg);
                                }
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.msg);
                        }
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setisButtonLoading(false);
                }
                getAllEnrollments();
            }
        } else {
            toast.error("Please Add Remarks First");
            setisButtonLoading(false);
        }
    };

    const runNLAD = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/verifyEnroll?enrollmentId=${rowData?._id}`);
            if (response?.status === "200" || response?.status === "200") {
                toast.success("Successfully Verify");
                setisButtonLoading(false);
            }
        } catch (error) {
            const body = error?.response?.data?.data?.body;
            const errorMessage = Array.isArray(body) ? body.toString() : body && typeof body === "object" ? JSON.stringify(body) : body;
            const msgerror = error?.response?.data?.msg;
            if (body) {
                toast.error(errorMessage);
            } else if (msgerror) {
                toast.error(error?.response?.data?.msg);
            }
            setisButtonLoading(false);
        }
    };

    const runNV = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/verifyEligibility?enrollmentId=${rowData?._id}`);
            if (response?.status == 200 || response?.status == 201) {
                const link1 = response?.data?.data?._links?.certification?.href;
                const link2 = response?.data?.data?._links?.resolution?.href;
                if (link1) {
                    setLink(link1);
                } else {
                    setLink(link2);
                }
                const respMsg = response?.data?.data?.status;

                if (respMsg.includes("complete") || respMsg.includes("COMPLETE") || respMsg.includes("Complete")) {
                    toast.success(response?.data?.data?.status);
                } else {
                    toast.warning(response?.data?.data?.status);
                }

                setSelectedRow(rowData);
            }
        } catch (error) {
            const status = error?.response?.status;

            if (status === 500 || status === 400) {
                toast.error(error?.response?.data?.data?.message);
            } else {
                const error1 = error?.response?.data?.data?.body;
                const error2 = error?.response?.data?.data?.errors[0]?.description;

                const errorMessage1 = Array.isArray(error1) ? error1.toString() : error1 && typeof error1 === "object" ? JSON.stringify(error1) : error1;
                const errorMessage2 = Array.isArray(error2) ? error2.toString() : error2 && typeof error2 === "object" ? JSON.stringify(error2) : error2;
                if (errorMessage1) {
                    toast.error("Error is " + errorMessage1);
                } else {
                    toast.error("Error is " + errorMessage2);
                }
            }
        } finally {
            setisButtonLoading(false);
        }
    };

    const enrollUser = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/enrollVerifiedUser?userId=${parseLoginRes?._id}&enrollmentId=${rowData?._id}`);

            if (response?.status == "200" || response?.status == "201") {
                toast.success("Successfully Enrolled");
                getAllEnrollments();
                setisButtonLoading(false);
            }
        } catch (error) {
            const body = error?.response?.data?.data?.body;

            const errorMessage = Array.isArray(body) ? body.toString() : body && typeof body === "object" ? JSON.stringify(body) : body;
            toast.error("Error is " + errorMessage);
            setisButtonLoading(false);
        }
    };

    const updateUser = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await Axios.put(`${BASE_URL}/api/user/updateVerifiedUser?enrollmentId=${rowData?._id}`);
            if (response?.status == "200" || response?.status == "201") {
                toast.success("Successfully Verify");
                setisButtonLoading(false);
            }
        } catch (error) {
            toast.error(`error is ${error?.response?.data?.data?.body[0]}`);

            setisButtonLoading(false);
        }
    };

    const HnadleAllApprove = async (rowData) => {
        setisButtonLoading(true);
        if (allEnrollments) {
            const enrollmentIds = allEnrollments.map((enrollment) => enrollment._id);

            const dataToSend = {
                approvedBy: parseLoginRes?._id,
                enrolmentIds: enrollmentIds,
                approved: true,
            };
            try {
                const response = await Axios.patch(`${BASE_URL}/api/user/prePostapproval`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    toast.success("Approved");
                    setisButtonLoading(false);
                    getAllEnrollments();
                    const dataToSend = {
                        orderNumber: rowData?.enrollmentId,
                    };
                    try {
                        const response = await Axios.post(`${BASE_URL}/api/web/order`, dataToSend);
                        if (response?.status == 200 || response?.status == 201) {
                            const orderId = response?.data?.data?.orderId;

                            const dataToSend = {
                                userId: parseLoginRes?._id,
                                testLabel: "false",
                                orderId: orderId,
                            };
                            try {
                                const response = await Axios.post(`${BASE_URL}/api/web/order/createLable`, dataToSend);
                                if (response?.status == 200 || response?.status == 201) {
                                }
                            } catch (error) {
                                toast.error(error?.response?.data?.msg);
                            }
                        }
                    } catch (error) {
                        toast.error(error?.response?.data?.msg);
                    }
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
            setisButtonLoading(false);
        } else {
            toast.error("No Enrollment Found");
            setisButtonLoading(false);
        }
    };

    const handleApproveSelected = async (rowData) => {
        setisButtonLoading(true);
        if (allEnrollments) {
            const enrollmentIds = selectedRows.map((enrollment) => enrollment._id);

            const dataToSend = {
                approvedBy: parseLoginRes?._id,
                enrolmentIds: enrollmentIds,
                approved: true,
            };
            try {
                const response = await Axios.patch(`${BASE_URL}/api/user/prePostapproval`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    toast.success("Approved");
                    setisButtonLoading(false);
                    getAllEnrollments();
                    const dataToSend = {
                        orderNumber: rowData?.enrollmentId,
                    };
                    try {
                        const response = await Axios.post(`${BASE_URL}/api/web/order`, dataToSend);
                        if (response?.status == 200 || response?.status == 201) {
                            const orderId = response?.data?.data?.orderId;

                            const dataToSend = {
                                userId: parseLoginRes?._id,
                                testLabel: "false",
                                orderId: orderId,
                            };
                            try {
                                const response = await Axios.post(`${BASE_URL}/api/web/order/createLable`, dataToSend);
                                if (response?.status == 200 || response?.status == 201) {
                                }
                            } catch (error) {
                                toast.error(error?.response?.data?.msg);
                            }
                        }
                    } catch (error) {
                        toast.error(error?.response?.data?.msg);
                    }
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
            setisButtonLoading(false);
        } else {
            toast.error("No Enrollment Found");
            setisButtonLoading(false);
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button label="Edit" onClick={() => viewRow(rowData)} text raised disabled={isButtonLoading} className="pt-1 pb-1" />
                <Button label="Approve" onClick={() => approveRow(rowData)} className=" p-button-success mr-2 ml-2 pt-1 pb-1 " text raised disabled={isButtonLoading} />
                <Button label="Reject" onClick={() => handleOpenDialog(rowData)} className=" p-button-danger mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
            </div>
        );
    };
    const actionTemplateForTL = (rowData) => {
        return (
            <div>
                {companyName.includes("IJ") || companyName.includes("ij") || companyName.includes("ZISFONE") || companyName.includes("Zisfone") ? (
                    <Button label="Add Remarks" onClick={() => handleOpenDialogForRemarksForIJ(rowData)} className=" p-button-sucess mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                ) : (
                    <Button label="Add Remarks" onClick={() => handleOpenDialogForRemarks(rowData)} className="pt-1 pb-1 p-button-sucess mr-2 ml-2" text raised disabled={isButtonLoading} />
                )}
                <Button label="Edit" onClick={() => viewRow(rowData)} className="pt-1 pb-1" text raised disabled={isButtonLoading} />
                <Button
                    label="Approve"
                    onClick={() => {
                        if (!rowData.QualityRemarks) {
                            toast.error("Please Add Remarks");
                        } else if (rowData.QualityRemarks === "satisfactory" || rowData.QualityRemarks === "good" || rowData.QualityRemarks === "average") {
                            approveRowByTl(rowData);
                        } else {
                            if (rowData.QualityRemarks !== undefined) {
                                toast.error("Only enrollment with call quality marked as good, average or satisfactory will be Approved");
                            } else {
                                toast.error("Please Add Remarks");
                            }
                        }
                    }}
                    className=" p-button-success mr-2 ml-2  pt-1 pb-1 "
                    text
                    raised
                    disabled={isButtonLoading}
                />
                <Button
                    label="Reject"
                    onClick={() => {
                        if (!rowData?.QualityRemarks) {
                            toast.error("Please Add Remarks");
                        } else if (rowData.QualityRemarks === "declined") {
                            handleOpenDialog(rowData);
                        } else {
                            if (rowData.QualityRemarks !== undefined) {
                                toast.error("Only enrollment with call quality marked as declined will be rejected");
                            } else {
                                toast.error("Please Add Remarks");
                            }
                        }
                    }}
                    className=" p-button-danger pt-1 pb-1 mr-2 ml-2"
                    text
                    raised
                    disabled={isButtonLoading}
                />{" "}
                {/*  <Button label="Files" onClick={() => {
                       localStorage.setItem("selectedId",JSON.stringify(rowData?._id))
                      navigate("/viewfile")
                }} className="pt-1 pb-1" text raised disabled={isButtonLoading} /> 
            */}
            </div>
        );
    };

    const actionTemplateForPR = (rowData) => {
        return (
            <div>
                <Button label="Edit" onClick={() => viewRow(rowData)} text raised className="pt-1 pb-1" disabled={isButtonLoading} />
                <Button label="Reject" onClick={() => handleOpenDialog(rowData)} className=" p-button-danger pt-1 pb-1 mr-2 ml-2" text raised disabled={isButtonLoading} />
                {/* <Button label="Run NLAD" onClick={() => runNLAD(rowData)} className=" mr-2 ml-2" text raised disabled={isButtonLoading} /> */}
                {/* <Button label="Run NV" onClick={() => runNV(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                {selectedRow === rowData && link ? (
                    <Button
                        label="Go To Link"
                        onClick={() => {
                            window.open(link, "_blank");
                        }}
                        className=" mr-2 ml-2 pt-1 pb-1 p-button-warning"
                        text
                        raised
                        disabled={isButtonLoading}
                    />
                ) : null} */}
                {/* <Button label="Enroll User" onClick={() => enrollUser(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} /> */}
                <Button label="Activate Sim" onClick={() => handleDialogeForActivate(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                {/* <Button label="Update User With NLAD" onClick={() => updateUser(rowData)} className=" mr-2 ml-2" text raised disabled={isButtonLoading} /> */}
                {/* <Button label="Transfer User" onClick={() => transferUser(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text  raised disabled={isButtonLoading} /> */}
            </div>
        );
    };
    const handleOpenDialog = (rowData) => {
        setisButtonLoading(true);
        setIsModalOpen(true);
        setIsEnrolmentId(rowData?._id);
        setCsrId(rowData?.csr);
        setCheckType(rowData?.enrollment);
        setisButtonLoading(false);
    };

    const handleDialogeForActivate = (rowData) => {
        setOpenDialogeForActivate(true);
        setIsEnrolmentId(rowData?._id);
        setZipCode(rowData?.zip);
    };
    const handleOpenDialogForRemarks = (rowData) => {
        setOpenDialogeForRemarks(true);
        setIsEnrolmentId(rowData?._id);
    };

    const handleOpenDialogForRemarksForIJ = (rowData) => {
        setOpenDialogeForRemarksForIJ(true);
        setIsEnrolmentId(rowData?._id);
    };

    // const handleApproveSelectedForQa=()=>{
    //     const enrollmentIds = selectedRows.map((enrollment) => enrollment._id);

    //     setSelectedIdsForApprove(enrollmentIds)
    //     setDialogeForApprove(true);

    // }
    // const HnadleAllApproveForQa=()=>{

    // }

    // const header = () => {
    //     return (
    //         <div className="flex flex-wrap justify-content-center mt-2">
    //             <Dropdown
    //                 className="mt-2 w-15rem ml-4"
    //                 options={[
    //                     { label: "Self Enrollment", value: "Self Enrollments" },
    //                     { label: "Enrollment", value: "Enrollment" },
    //                     { label: "All Enrollments", value: null },
    //                 ]}
    //                 value={enrollmentIdFilterValue}
    //                 onChange={(e) => {
    //                     onNameDateEnrollmentIdValueFilter(e, "enrollment");
    //                 }}
    //                 placeholder="Enrollment Type"
    //             />
    //             <InputText value={globalFilterValue} onChange={onGlobalFilterValueChange} className="w-15rem ml-4 mt-2" placeholder="Search..." />
    //             <div className="w-45rem ml-4 mt-2">
    //                 <Calendar
    //                     className="w-21rem"
    //                     value={createDateFilterValue}
    //                     dateFormat="mm/dd/yy"
    //                     placeholder="Start Date"
    //                     onChange={(e) => {
    //                         onNameDateEnrollmentIdValueFilter(e, "createdAt");
    //                     }}
    //                     showIcon
    //                 />
    //                 <label className="p-2" style={{ fontSize: "19px", textAlign: "center", color: "grey" }}>
    //                     To
    //                 </label>
    //                 <Calendar
    //                     className="w-21rem"
    //                     value={createDateToFilterValue}
    //                     dateFormat="mm/dd/yy"
    //                     placeholder="End Date"
    //                     onChange={(e) => {
    //                         onNameDateEnrollmentIdValueFilter(e, "createdTo");
    //                     }}
    //                     showIcon
    //                 />
    //             </div>
    //         </div>
    //     );
    // };
    const header = () => {
        return (
            <div className="flex flex-wrap justify-content-left mt-2 w-full maindiv" style={{ backgroundColor: "white" }}>
                <div className="flex flex-wrap w-full inputtext" style={{ height: "45px", border: "none", alignItems: "center", backgroundColor: "white" }}>
                    <span className="pi pi-search mt-1" style={{ fontSize: "16px", marginLeft: "5px" }}></span>
                    <InputText className="searchh" value={globalFilterValue} onChange={onGlobalFilterValueChange} placeholder="Search..." style={{ height: "35px", width: "18%" }} />
                    <Dropdown
                        className="enrollment"
                        options={[
                            { label: "Self Enrollment", value: "Self Enrollments" },
                            { label: "Enrollment", value: "Enrollment" },
                            { label: "All Enrollments", value: null },
                        ]}
                        value={enrollmentIdFilterValue}
                        onChange={(e) => {
                            onNameDateEnrollmentIdValueFilter(e, "enrollment");
                        }}
                        placeholder="Enrollment Type"
                        style={{ color: "white" }}
                    />
                    <div
                        className="cal"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "43%",
                        }}
                    >
                        <span style={{ fontWeight: "400", paddingLeft: "2%" }}>From</span>
                        <Calendar
                            value={createDateFilterValue}
                            dateFormat="mm/dd/yy"
                            // placeholder="Start Date"
                            onChange={(e) => {
                                onNameDateEnrollmentIdValueFilter(e, "createdAt");
                            }}
                            showIcon
                            style={{ height: "30px", width: "40%" }}
                        />
                        <span style={{ fontWeight: "400" }}>To</span>
                        <Calendar
                            value={createDateToFilterValue}
                            dateFormat="mm/dd/yy"
                            // placeholder="End Date"
                            onChange={(e) => {
                                onNameDateEnrollmentIdValueFilter(e, "createdTo");
                            }}
                            showIcon
                            style={{ height: "30px", border: "none", width: "40%", color: "red" }}
                        />
                    </div>

                    {/* <div className="w-20rem ml-4 mt-2">
                        <label className="p-2" style={{ fontSize: "19px", textAlign: "center", color: "grey" }}>
                            To
                        </label>
                    </div> */}
                </div>
            </div>
        );
    };
    const transferUser = async (rowData) => {
        setDialogeForTransfer(true);
        setIsEnrolmentId(rowData?._id);
    };
    return (
        <>
            <ToastContainer className="custom-toast-container" />
            <div className="card ">
                <form>
                    <Dialog visible={isModalOpen} style={{ width: "50vw" }} draggable={false} onHide={() => setIsModalOpen(false)}>
                        <DialogForReject setIsModalOpen={setIsModalOpen} checkType={checkType} enrollmentId={isEnrolmentId} CSRid={CsrId} getAllEnrollments={getAllEnrollments} />
                    </Dialog>
                    <Dialog header={"Activate Sim"} visible={openDialogeForActivate} style={{ width: "70vw" }} onHide={() => setOpenDialogeForActivate(false)}>
                        <DialogForActivateSim enrollmentId={isEnrolmentId} setOpenDialogeForActivate={setOpenDialogeForActivate} zipCode={zipCode} />
                    </Dialog>
                    <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarks} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarks(false)}>
                        <DialogeForRemarks getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} />
                    </Dialog>
                    <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarksForIJ} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarksForIJ(false)}>
                        <DialogeForRemarksForIJ getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} setOpenDialogeForRemarksForIJ={setOpenDialogeForRemarksForIJ} />
                    </Dialog>
                    <Dialog header={"Transfer User"} visible={dialogeForTransfer} style={{ width: "30vw" }} onHide={() => setDialogeForTransfer(false)}>
                        <DialogeForTransferUser enrollmentId={isEnrolmentId} setDialogeForTransfer={setDialogeForTransfer} />
                    </Dialog>
                    {/* <Dialog header={"Add Remarks"} visible={dialogeForApprove} style={{ width: "30vw" }} onHide={() => setDialogeForApprove(false)}>
                        <DialogeForApprove enrollmentIds={selectedIdsForApprove} />
                    </Dialog> */}
                </form>
                <div className="text-center w-full h-4 heading">
                    <h3 className="bg-red-500">ALL ENROLLMENTS</h3>
                </div>
                <div className="card mx-5 p-0 ">
                    <div className="flex font-bold pt-2">
                        <div className=" ml-auto flex">
                            <div className="mr-5"></div>
                            <div className="  flex pr-4 ">
                                {roleName == "CSR" || roleName == "csr" || roleName == "Csr" ? (
                                    ""
                                ) : (
                                    //  roleName == "QA" || roleName == "qa" || roleName == "Qa" ?
                                    //   <Button label="Approve All Enrollments" icon={PrimeIcons.CHECK} onClick={() => HnadleAllApproveForQa()} className=" p-button-success  ml-3  " text raised disabled={isButtonLoading} />
                                    // :
                                    <Button label="Approve All Enrollments" icon={PrimeIcons.CHECK} onClick={() => HnadleAllApprove()} className=" p-button-success  ml-3 card " text disabled={isButtonLoading} />
                                )}

                                {roleName == "CSR" || roleName == "csr" || roleName == "Csr" ? (
                                    ""
                                ) : (
                                    //  :
                                    //   roleName == "QA" || roleName == "qa" || roleName == "Qa" ?
                                    //   <Button label="Approve Selected" icon={PrimeIcons.CHECK} onClick={handleApproveSelectedForQa} className="p-button-success ml-3" text raised disabled={isButtonLoading || selectedRows.length === 0} />
                                    <Button label="Approve Selected" icon={PrimeIcons.CHECK} onClick={handleApproveSelected} className="p-button-success ml-3 card" text disabled={isButtonLoading || selectedRows.length === 0} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        {isButtonLoading ? <ProgressSpinner className="flex flex-wrap justify-content-center flex-row mt-4" /> : null}

                        <DataTable
                            value={filteredDates || allEnrollments}
                            selection={selectedRows}
                            onSelectionChange={(e) => setSelectedRows(e.value)}
                            size="small"
                            stripedRows
                            resizableColumns
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[25, 50]}
                            filters={filters}
                            globalFilterFields={["name", "enrollmentId"]}
                            header={header}
                            emptyMessage="No customers found."
                        >
                            {/* <Column expander style={{ width: "3em" }} /> */}
                            {/* <Column header="SNo" style={{ width: "3em" }} body={(rowData, rowIndex) => (rowIndex + 1).toString()} /> */}
                            <Column selectionMode="multiple" style={{ width: "3em" }} />
                            <Column
                                header="Enrollment ID"
                                field="enrollmentId"
                                body={(rowData) => (
                                    <button style={{ border: "none", backgroundColor: "white", cursor: "pointer" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                                        {rowData.enrollmentId}
                                    </button>
                                )}
                            ></Column>
                            <Column header="Enrollment Type" field="enrollment"></Column>

                            <Column header="Name" field="name"></Column>
                            <Column header="Address" field="address1"></Column>
                            <Column header="City" field="city"></Column>
                            <Column header="State" field="state"></Column> 
                            <Column header="Linked Account" body={(rowData)=>{ 
                         return( 
                            <p>{rowData?.linkedAccount ? "Yes":"No"}</p>
                         )
                             }}   />
                            <Column header="Zip" field="zip" />
                            <Column
                                field="DOB"
                                header="DOB"
                                body={(rowData) =>
                                    new Date(rowData.DOB)
                                        .toLocaleDateString("en-US", {
                                            month: "2-digit",
                                            day: "2-digit",
                                            year: "numeric",
                                        })
                                        .replace(/\//g, "-")
                                }
                            />
                            <Column field="contact" header="Contact" />
                            <Column field="createdBy.name" header="Created By" />
                            <Column field="createdDate" header="Created At" />
                            {toCapital.includes("QA MANAGER") ? <Column field="assignToQa.name" header="Initial Assignee" /> : ""}

                            <Column
                                field="Phase"
                                header="Phase"
                                body={(rowData) => (
                                    <span>
                                        {rowData.assignedToUser.map((user) => (
                                            <span key={user?.department?.department}>{user?.department?.department}</span>
                                        ))}
                                    </span>
                                )}
                            />
                            <Column field="selectProduct" header="Product" />
                            <Column field="currentPlan.planName" header="Plan" />
                            <Column field="grandTotal" header="Price" />

                            {toCapital == "CSR" || toCapital == "CS" || toCapital == "TEAM LEAD" || toCapital == "CS MANAGER" ? (
                                ""
                            ) : roleName.includes("provision") || roleName.includes("Provision") || roleName.includes("PROVISION") || roleName.includes("retention") || roleName.includes("RETENTION") || roleName.includes("Retention") ? (
                                <Column header="Actions" body={actionTemplateForPR}></Column>
                            ) : roleName.includes("QA") || roleName.includes("qa") || roleName.includes("Qa") ? (
                                <Column header="Actions" body={actionTemplateForTL}></Column>
                            ) : (
                                <Column header="Actions" body={actionTemplate}></Column>
                            )}
                        </DataTable>
                        {isLoading ? <ProgressSpinner className="flex flex-wrap justify-content-center flex-row mt-4" /> : null}
                    </div>
                </div>
                <br />
            </div>
        </>
    );
};
export default All_Enrollments;
