import React, { useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Preview_Final_component from "./Preview_Final_component";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import "./Preview.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Preview = ({ initiationData, parentDetails, isPostpaidIncomplete, setConfirmationActive, currentAllBillingConfig, currentAllPlans, setPreviewScreenActive, setPaymentScreenActive, paymentScreenDetails, paymentDone, setActiveIndex }) => {
    const [showFinalComponent, setShowFinalComponent] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const parseLoginRes = loginRes;

    const _id = initiationData?._id;
    const csr = loginRes?._id;
    const [checked, setChecked] = useState(false);
    const [fromIncomplete, setFromIncomplete] = useState(false);
    //get preview  information from local storage
    const previewsRes = localStorage.getItem("prepaidaddress");
    const parsepreviewsRes = JSON.parse(previewsRes);
    const previewInfo = parsepreviewsRes?.data;
    const zipRes = localStorage.getItem("prepaidzipData");
    //check that user come from incomplete or not
    const fromIncompl = localStorage.getItem("comingfromincomplete");
    const parsefromIncompl = JSON.parse(fromIncompl);
    const formatDate = (date) => {
        if (!date) return ""; // Handle null or undefined dates
        return new Date(date).toLocaleDateString("en-US");
    };
    const [additionalFeatureInCasePayment, setAdditionalFeatureInCasePayment] = useState("");
    const [discountInCasePayment, setDiscountInCasePayment] = useState("");
    const [totalAmountIncasPaymentincomplete, setTotalAmountIncasepaymentincomplete] = useState("");
    const [amountPaidIncasepayment, setAmountpaidincasepayment] = useState("");
    useEffect(() => {
        if (isPostpaidIncomplete && isPostpaidIncomplete?.activeBillingConfiguration) {
            if (Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0) {
                let additionalincasincompletepaymentdone = "";
                if (isPostpaidIncomplete.activeBillingConfiguration?.additionalFeature) {
                    for (let i = 0; i < isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature?.length; i++) {
                        if (i + 1 === isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature?.length) {
                            additionalincasincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature[i]?.name}`;
                        } else {
                            additionalincasincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature[i]?.name},`;
                        }
                    }
                    setAdditionalFeatureInCasePayment(additionalincasincompletepaymentdone);
                }
                let discountincaseincompletepaymentdone = "";
                if (isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount) {
                    for (let i = 0; i < isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount?.length; i++) {
                        if (i + 1 === isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount?.length) {
                            discountincaseincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount[i]?.name}`;
                        } else {
                            discountincaseincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount[i]?.name},`;
                        }
                    }
                    setDiscountInCasePayment(discountincaseincompletepaymentdone);
                }
                if (isPostpaidIncomplete?.invoice?.length > 0) {
                    Axios.get(`${BASE_URL}/api/web/invoices/getinvoicebyid?invoiceId=${isPostpaidIncomplete?.invoice[0]}`)
                        .then((res) => {
                            setTotalAmountIncasepaymentincomplete(res?.data?.data?.totalAmount);
                            setAmountpaidincasepayment(res?.data?.data?.amountPaid);
                        })
                        .catch((err) => {});
                }
            } else {
            }
        }
    }, []);
    const PostpaidHandover = async () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
        };
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/handOverEnrollment`, dataToSend);
            setIsLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
        setShowFinalComponent(true);
    };
    const postData = async () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
            isWithInvoice: paymentScreenDetails?.prospectwithinvoice ? paymentScreenDetails?.prospectwithinvoice : false,
            isWithoutInvoice: paymentScreenDetails?.prospectwithinvoice ? paymentScreenDetails?.prospectwithinvoice : false,
        };
        Axios.post(`${BASE_URL}/api/user/esnAssingment`, dataToSend)
            .then(() => {
                toast.success("Esn Successfully Assigned");
                Axios.post(`${BASE_URL}/api/user/prepaidHandOver`, dataToSend)
                    .then((res) => {
                        Axios.post(`${BASE_URL}/api/web/order`, { orderNumber: initiationData?.enrollmentId })
                            .then((response) => {
                                toast.success("Order Placed Successfully");
                                let generate = localStorage.getItem("generatelabel");
                                generate = generate === "true"; // convering to boolean
                                if (generate) {
                                    Axios.post(`${BASE_URL}/api/web/order/createLable`, { orderId: response.data.data.orderId.toString(), userId: _id, testLabel: true })
                                        .then(() => {
                                            toast.success("Label created Successfully");
                                            setIsLoading(false);

                                            setShowFinalComponent(true);
                                            setFromIncomplete(false);
                                        })
                                        .catch((err) => {
                                            // toast.error("Label Creation Failed");
                                            // toast.success("Label created Successfully");
                                            setIsLoading(false);

                                            setShowFinalComponent(true);
                                            setFromIncomplete(false);
                                        });
                                } else {
                                    setShowFinalComponent(true);
                                    setFromIncomplete(false);
                                }
                            })
                            .catch((err) => {
                                toast.error("Order Placing Failed");
                            });
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.msg);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.response?.data?.msg);
            });
    };
    useEffect(() => {
        if (!zipRes && parsefromIncompl == false) {
            setIsChecked(true);
        } else if (!zipRes && parsefromIncompl == true) {
            setIsChecked(false);
        }
    }, []);
    let inventory;
    let oneTimeCharge;
    let discount = "";
    let additional = "";
    let discountobjectsendin = [];
    let additionalobjectsendin = [];
    let productName;
    let dueDate;
    let applyLateFee;
    let planname;
    let plancharges;

    for (let i = 0; i < currentAllPlans?.length; i++) {
        if (paymentScreenDetails?.plan === currentAllPlans[i]?._id) {
            planname = currentAllPlans[i]?.name;
            plancharges = currentAllPlans[i]?.price;
            inventory = currentAllPlans[i]?.inventoryType;
            //planId = currentAllPlans[i]?._id;
        }
    }

    productName = "SIM";
    let selectdiscount = paymentScreenDetails?.discount;
    let alldiscounts = currentAllBillingConfig?.selectdiscount;
    applyLateFee = currentAllBillingConfig?.applyLateFee;
    dueDate = currentAllBillingConfig?.dueDate;
    oneTimeCharge = currentAllBillingConfig?.oneTimeCharge;
    let simalladditional = currentAllBillingConfig?.additionalFeature;
    let additionallocal = paymentScreenDetails?.additional;
    for (let i = 0; i < additionallocal?.length; i++) {
        for (let k = 0; k < simalladditional?.length; k++) {
            if (additionallocal[i] === simalladditional[k]._id) {
                let obj = {
                    name: simalladditional[k]?.featureName,
                    amount: simalladditional[k]?.featureAmount,
                };
                additionalobjectsendin.push(obj);
                if (i + 1 === additionallocal?.length) {
                    additional += `${simalladditional[k].featureName}`;
                } else {
                    additional += `${simalladditional[k].featureName},`;
                }
            }
        }
    }

    for (let k = 0; k < selectdiscount?.length; k++) {
        for (let i = 0; i < alldiscounts?.length; i++) {
            if (selectdiscount[k] === alldiscounts[i]._id) {
                let obj = {
                    name: alldiscounts[i]?.discountname,
                    amount: alldiscounts[i]?.amount,
                };
                discountobjectsendin.push(obj);
                if (k + 1 === selectdiscount?.length) {
                    discount += `${alldiscounts[i].discountname}`;
                } else {
                    discount += `${alldiscounts[i].discountname},`;
                }
            }
        }
    }

    const handleSign = () => {
        setChecked(true);
    };
    const postDataWithinvoice = () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
            isWithInvoice: paymentScreenDetails?.prospectwithinvoice,
            isWithoutInvoice: paymentScreenDetails?.prospectwithoutinvoice,
        };

        const shipmentName = localStorage.getItem("shipmentName");
        const shipmentCost = localStorage.getItem("shipmentCost");
        Axios.post(`${BASE_URL}/api/user/prepaidHandOver`, dataToSend)
            .then(() => {
                let dataToSend = {
                    customerId: paymentScreenDetails.customerid,
                    invoiceType: "Sign Up",
                    totalAmount: paymentScreenDetails.totalamount,
                    additionalCharges: additionalobjectsendin,
                    discount: discountobjectsendin,
                    amountPaid: 0,
                    selectProduct: paymentScreenDetails.billId,
                    invoiceDueDate: dueDate,
                    lateFee: applyLateFee,
                    invoiceOneTimeCharges: oneTimeCharge,
                    invoiceStatus: "Unpaid",
                    planId: paymentScreenDetails?.plan,
                    planName: planname,
                    planCharges: plancharges,

                    invoicePaymentMethod: "Skip Payment",
                    chargingType: "monthly",
                    invoicePaymentMethod: "",

                    invoicePaymentMethod: "Skip Payment",
                    printSetting: "Both",
                    isWithInvoice: paymentScreenDetails?.prospectwithinvoice,
                    isWithoutInvoice: paymentScreenDetails?.prospectwithoutinvoice,
                    isInvoice: paymentScreenDetails.prospectwithinvoice,
                    billingPeriod: {
                        from: "onActivation",
                        to: "onActivation",
                    },
                    isShipmentCostDiscounted: checkLabel,
                    shipmentCost: {
                        name: shipmentName,
                        amount: shipmentCost,
                    },
                    userId: parseLoginRes?._id,
                };

                Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend).then(() => {
                    // toast.success("Label created Successfully");

                    setIsLoading(false);
                    setShowFinalComponent(true);
                    setFromIncomplete(false);
                });
            })
            .catch((error) => {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            });
    };
    function ChangeIsoDateToECT(date) {
        // Given ISO formatted date/time
        const isoDate = date;

        // Convert ISO string to Date object
        const utcDate = new Date(isoDate);

        // Format the date according to Eastern Time Zone (EST/EDT)
        const estTimeString = utcDate.toLocaleString("en-US", {
            timeZone: "America/New_York",
        });
        return estTimeString;
    }
    return (
        <>
            <ToastContainer />
            {!showFinalComponent ? (
                <div>
                    <br></br>

                    <div>
                        <div style={{ marginTop: "20px" }}>
                            {/* <h5 className="font-bold">ENROLLMENT ID: {enrollment_id}</h5> */}
                            <h2 className="prev">Preview Your Details</h2>
                        </div>

                        <br />

                        <div className="previewdiv">
                            <div className="flex justify-content-around">
                                <div className="pt-2" style={{ borderRight: "1px solid #0475B4", width: "45%", borderStyle: "dashed", borderLeft: "none", borderBottom: "none", height: "280px", borderTop: "none", marginTop: "20px", marginBottom: "20px" }}>
                                    <div className="flex ">
                                        <p className="w-6 ml-4">First Name:</p>
                                        <p className="w-6">{parentDetails?.firstName || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Service Address:</p>
                                        <p className="w-6">{parentDetails?.address1 || "-"}</p>
                                    </div>

                                    <div className="flex  ">
                                        <p className="w-6 ml-4">State:</p>
                                        <p className="w-6">{parentDetails?.state || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">DOB:</p>
                                        <p className="w-6">{formatDate(parentDetails?.DOB || "-")}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Telephone:</p>
                                        <p className="w-6">{parentDetails?.contact || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Discounts:</p>
                                        <p className="w-6"> {isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 ? discountInCasePayment : discount?.toUpperCase() || "-"}</p>
                                    </div>

                                    <div className="flex    ">
                                        <p className="w-6 ml-4">Net Amount: </p>
                                        {/* <p className="w-6">{paymentScreenDetails?.paid ? `$${paymentScreenDetails.paid}` : "-"}</p> */}
                                        <p className="w-6">{isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 ? totalAmountIncasPaymentincomplete : paymentScreenDetails?.totalamount ? `$${paymentScreenDetails?.totalamount}` : "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Inventory: </p>
                                        <p className="w-6">{isPostpaidIncomplete ? (isPostpaidIncomplete !== "" && isPostpaidIncomplete.selectProduct ? isPostpaidIncomplete?.selectProduct : inventory?.toUpperCase()) : inventory?.toUpperCase()}</p>
                                    </div>
                                </div>
                                <div className=" w-5 pt-4 " style={{ paddingLeft: "20px" }}>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">LastName:</p>
                                        <p className="w-6">{parentDetails?.lastName.toUpperCase() || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">City:</p>
                                        <p style={{ marginLeft: "-10px" }}>{parentDetails?.city?.toUpperCase() || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Zip Code:</p>
                                        <p className="w-6">{parentDetails?.zip || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">SSN:</p>
                                        <p className="w-6">{parentDetails?.SSN || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Email:</p>
                                        <p className="w-6">{parentDetails?.email.toUpperCase() || "-"}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Plan:</p>
                                        <p className="w-6">{isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete.currentPlan?.planName ? isPostpaidIncomplete.currentPlan?.planName : (planname || "-").toUpperCase()}</p>
                                    </div>
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Amount Paid: </p>
                                        <p className="w-6">{isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 ? amountPaidIncasepayment : paymentScreenDetails?.paid ? `$${paymentScreenDetails.paid}` : "-"}</p>
                                    </div>

                                    {/*  <div className="flex  ">
                                    <p className="w-6 ml-4">Inventory:</p>
                                    <p className="w-6">{inventory}</p>
                                </div>     */}
                                    <div className="flex  ">
                                        <p className="w-6 ml-4">Additional Feature:</p>
                                        <p className="w-6">
                                            <div>
                                                <p className="inline">{isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 ? additionalFeatureInCasePayment : additional.toUpperCase() || "-"}</p>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <br />
                        <div className="flex">
                            <Checkbox inputId="cb1" value="New York" checked={isChecked} onClick={handleSign} onChange={(e) => setIsChecked(e.checked)}></Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label mx-2">
                                <p style={{ fontFamily: "Inter", fontSize: "14px" }}>
                                    I further consent to receive calls, emails and/or text messages that may deliver auto-dialed or pre-recorded messages from {parseLoginRes?.companyName}, Inc or its duly appointed agent, either using my telephone number assigned by {parseLoginRes?.companyName}, Inc
                                    or provided by me herein or later. I understand this is not a condition of purchase.
                                </p>
                                <p style={{ fontFamily: "Inter", fontSize: "14px" }}>
                                    I hereby give my informed consent to electronically sign this form, and I acknowledge that this electronic signature has the same legal effect as a handwritten signature. I understand that this action signifies my agreement to the terms and conditions outlined in
                                    this form and any related documents.
                                </p>
                                <br />
                                {checked ? (
                                    <p style={{ fontFamily: "Inter", fontSize: "14px" }}>
                                        <strong>
                                            This form is electronically signed by <span> </span>
                                            <strong>
                                                {parentDetails?.firstName + " " + parentDetails.lastName}
                                                {/* {previewInfo && previewInfo.firstName ? previewInfo.firstName.toUpperCase() : "Unknown"} {previewInfo && previewInfo.lastName ? previewInfo.lastName.toUpperCase() : "User"} */}
                                            </strong>{" "}
                                            <span> </span>
                                            on <span> </span> {ChangeIsoDateToECT(new Date().toISOString())}
                                        </strong>
                                    </p>
                                ) : null}
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-row justify-content-end w-full ">
                        <div className="flex flex-row flex-wrap justify-content-left">
                            <Button
                                label="Back"
                                className="btn"
                                onClick={() => {
                                    if (isPostpaidIncomplete && isPostpaidIncomplete?.activeBillingConfiguration) {
                                        setConfirmationActive(true);
                                        setPreviewScreenActive(false);
                                    } else {
                                        setPaymentScreenActive(true);
                                        setPreviewScreenActive(false);
                                    }
                                }}
                            />
                            {/* <Button
                                className="btn ml-1"
                                label="Submit"
                                onClick={
                                    paymentDone
                                        ? postData
                                        : isPostpaidIncomplete && isPostpaidIncomplete !== "" && (isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length) > 0
                                          ? postData
                                          : (paymentDone && parentDetails?.accountType === "Postpaid") || (isPostpaidIncomplete && isPostpaidIncomplete !== "" && (isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length) > 0)
                                            ? PostpaidHandover
                                            : postDataWithinvoice
                                }
                                disabled={!isChecked}
                                icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                            /> */}
                            <Button
                                className="btn ml-1"
                                label="Submit"
                                onClick={
                                    paymentDone
                                        ? postData
                                        : isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete.activeBillingConfiguration ?? {}).length > 0
                                          ? postData
                                          : (paymentDone && parentDetails?.accountType === "Postpaid") || (isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete.activeBillingConfiguration ?? {}).length > 0)
                                            ? PostpaidHandover
                                            : postDataWithinvoice
                                }
                                disabled={!isChecked}
                                icon={isLoading === true ? "pi pi-spin pi-spinner" : ""}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <Preview_Final_component parentDetails={parentDetails} initiationData={initiationData} enrollment_id={initiationData?.enrollmentId} />
            )}
        </>
    );
};

export default Preview;
