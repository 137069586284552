import React, { useEffect } from "react";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Axios from "axios";
import "./service_availability.css";
import { fas } from "@fortawesome/free-solid-svg-icons";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function InitiateEnrollment({ setParentDetails, setInitiationData, parentDetails, setPersonalInfoActive, setInitiateActive, billingModelSelected, setCurrentBillingModel, setBillingModelSelected, setZipVerified }) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isCreate, setIsCreate] = useState(false);
    const [pwgApiCheck, setPwgApiCheck] = useState("pending");
    const [pwgApiCheckFound, setPwgApiCheckFound] = useState(false);
    const [pwgDbCheck, setPwgDbCheck] = useState("pending");
    const [pwgDbCheckFound, setPwgDbCheckFound] = useState(false);
    const [uspsCheck, setUspsCheck] = useState("pending");
    const [uspsCheckFound, setUspsCheckFound] = useState(false);
    const [showCheckCoverage, setShowCheckCoverage] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const location = useLocation();
    const currentPath = location?.pathname;
    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);
        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        actionBasedChecks();
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let billingmodels = res?.data?.data;
                for (let i = 0; i < billingmodels.length; i++) {
                    if (billingmodels[i].billingModel === "POSTPAID") {
                        setCurrentBillingModel(billingmodels[i].billingModel);
                        setBillingModelSelected(billingmodels[i]._id);
                    }
                }
            })
            .catch((err) => {});
    }, []);

    // Get user data from localStorage
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const validationSchema = Yup.object().shape({
        zipCode: Yup.string().required("Please enter Zip code"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            zipCode: "",
        },
        onSubmit: async (values, actions) => {
            const serviceProvider = parseLoginRes?.company;
            const department = parseLoginRes?.department;
            const csr = parseLoginRes?._id;
            const dataToSend = { serviceProvider, csr, department, ...values, accountType: "Postpaid" };
            setIsLoading(true);
            setShowCheckCoverage(true);
            Axios.post(`${BASE_URL}/api/user/PWGverifyZip`, dataToSend)
                .then((res) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(true);
                    setParentDetails({
                        ...res?.data?.data,
                        accountType: "Postpaid",
                    });
                    setInitiationData({
                        ...res?.data?.data,
                        accountType: "Postpaid",
                    });
                    Axios.post(`${BASE_URL}/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                });
                        });
                })
                .catch((err) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(false);
                    Axios.post(`${BASE_URL}/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);
                            setParentDetails({
                                ...res?.data?.data,
                                accountType: "Postpaid",
                            });
                            setInitiationData({
                                ...res?.data?.data,
                                accountType: "Postpaid",
                            });
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setParentDetails({
                                        ...res?.data?.data,
                                        accountType: "Postpaid",
                                    });
                                    setInitiationData({
                                        ...res?.data?.data,
                                        accountType: "Postpaid",
                                    });
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        });
                });
        },
    });

    return (
        <div className="flex flex-column justify-content-center">
            <div className="grid justify-content-center align-content-center my-5">
                <div className="cardnewenrollment ">
                    <h1 className="heading">NEW ENROLLMENT</h1>
                    <hr className="hr w-full" />
                    <form className="my-4" onSubmit={formik.handleSubmit}>
                        <p style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "500", color: "black" }}>Please Enter The Zip Code To Check Service Availability</p>

                        {isLoading ? (
                            <InputText
                                type="text"
                                name="zipCode"
                                className="w-full mb-3"
                                value={formik.values.zipCode}
                                onChange={(e) => {
                                    formik.setFieldValue("zipCode", e.target.value);
                                    if (pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending") {
                                        formik.setFieldValue("zipCode", e.value);
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }
                                }}
                                keyfilter={/^\d{0,5}$/}
                                minLength={5}
                                maxLength={5}
                                disabled={isLoading}
                            />
                        ) : (
                            <InputText
                                type="text"
                                name="zipCode"
                                className="w-full mb-3"
                                value={formik.values.zipCode}
                                onChange={(e) => {
                                    formik.setFieldValue("zipCode", e.target.value);
                                    if (pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending") {
                                        formik.setFieldValue("zipCode", e.value);
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }
                                }}
                                keyfilter={/^\d{0,5}$/}
                                minLength={5}
                                maxLength={5}
                            />
                        )}

                        {formik.touched.zipCode && formik.errors.zipCode ? (
                            <p className="mt-0" style={{ color: "red", fontFamily: "Inter", marginLeft: "20px" }}>
                                {formik.errors.zipCode}
                            </p>
                        ) : null}
                        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                        {pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending" ? (
                            <Button
                                label={"Submit"}
                                type="button"
                                onClick={() => {
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(true);
                                }}
                                className="checkbutton"
                            />
                        ) : (
                            <Button label={"Check Coverage"} type="submit" className="checkbutton" disabled={isLoading || !isCreate} />
                        )}
                        {showCheckCoverage ? (
                            <div className="checkcoverageapi">
                                <Button iconPos="right" className="chkbtn mt-4 " type="button" icon={pwgApiCheck === "pending" ? "pi pi-spin pi-spinner" : pwgApiCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"} label="Checking Through Pwg Apis " />
                                <Button iconPos="right" className="mt-4  chkbtn" type="button" icon={pwgDbCheck === "pending" ? "pi pi-spin pi-spinner" : pwgDbCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"} label="Checking Through Pwg Database Data " />

                                <Button iconPos="right" className="chkbtn mt-4" type="button" icon={uspsCheck === "pending" ? "pi pi-spin pi-spinner" : uspsCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"} label="Checking Through USPS " />
                            </div>
                        ) : (
                            ""
                        )}
                        <Dialog className="proceed" header="PROCEEDING CONFIRMATION" headerClassName="dialogHeader" style={{ width: "40vw" }} visible={pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending" && !pwgApiCheckFound && !pwgDbCheckFound && !uspsCheckFound}>
                            <p>Still Want To Proceed</p>
                            <div className="mt-4 flex flex-wrap flex-row justify-content-center">
                                <Button
                                    label="Yes"
                                    style={{ backgroundColor: "#C68301", color: "white", border: "none", height: "40px", width: "70px" }}
                                    onClick={() => {
                                        const serviceProvider = parseLoginRes?.company;
                                        const department = parseLoginRes?.department;

                                        const csr = parseLoginRes?._id;

                                        const dataToSend = { serviceProvider, csr, department, ...formik.values, accountType: "Postpaid" };

                                        Axios.post(`${BASE_URL}/api/user/withoutzip`, dataToSend)
                                            .then((res) => {
                                                setParentDetails({
                                                    ...res?.data?.data,
                                                    accountType: "Postpaid",
                                                });
                                                setInitiationData({
                                                    ...res?.data?.data,
                                                    accountType: "Postpaid",
                                                });

                                                //localStorage.setItem("izZipVerified", "no");
                                                setZipVerified(true);
                                                setPersonalInfoActive(true);
                                                setInitiateActive(false);
                                            })
                                            .catch((err) => {});
                                    }}
                                />
                                <Button
                                    label="No"
                                    className="ml-4"
                                    style={{
                                        backgroundColor: "#C68301",
                                        color: "white",
                                        border: "none",
                                        height: "40px",
                                        width: "70px",
                                    }}
                                    onClick={() => {
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }}
                                />
                            </div>
                        </Dialog>
                    </form>
                </div>
            </div>
        </div>
    );
}
