import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Agree = ({ initiationData, isPrepaidIncomplete, setPreviewScreenActive, setParentDetails, parentDetails, setAddressActive, setConfirmationActive, setPaymentScreenActive }) => {
    const [checkAll, setCheckAll] = useState(parentDetails?.isAgreeToTerms ? true : false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const _id = initiationData?._id;
    const csr = loginRes?._id;
    const companyName = loginRes?.companyName;
    const validationSchema = Yup.object().shape({
        isTemporaryAddress: Yup.boolean().oneOf([true], "Please confirm the address is required"),
        checkbox: Yup.array()
            .of(Yup.boolean())
            .test("at-least-one-checked", "Please Select all CheckBox", (value) => value.some((val) => val === true)),
    });
    const initialValues = {
        checkAll: parentDetails?.isAgreeToTerms ? true : false,
        checkbox: new Array(14).fill(parentDetails?.isAgreeToTerms ? true : false),
        isTemporaryAddress: "",
    };

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: initialValues,
        onSubmit: async (values, actions) => {
            postdata();
        },
    });
    const postdata = async () => {
        setIsLoading(true);
        const userId = _id;
        const dataToSend = { csr, userId };
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/termsAndConditions`, dataToSend);
            if (response?.status === 201 || response?.status === 200) {
                //  localStorage.setItem("prepaidagreeData", JSON.stringify(response?.data));
                //  setIsBack(isBack + 1);
                // handleNext();
                setIsLoading(false);
                setParentDetails((prev) => ({
                    ...prev,
                    isAgreeToTerms: true,
                }));

                setConfirmationActive(false);
                setPaymentScreenActive(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);

            setIsLoading(false);
        }
    };

    const handleAllNew = () => {
        const newCheckBoxes = formik.values.checkbox.map(() => !checkAll);
        formik.setFieldValue("checkbox", newCheckBoxes);
        setCheckAll(!checkAll);
    };
    const handleCheckBox = (index) => {
        const newCheckBoxes = [...formik.values.checkbox];
        if (newCheckBoxes[index]) {
            if (checkAll) {
                setCheckAll(false);
            }
        } else {
        }
        newCheckBoxes[index] = !newCheckBoxes[index];
        formik.setFieldValue("checkbox", newCheckBoxes);
    };
    //`I hereby consent to enroll with ${companyName}, Inc for the selected services under the following terms and conditions: `,

    let checkBoxLabels = [
        `${companyName}, Inc agrees to provide the selected equipment and services to the customer at the rates discussed with the customer`,
        `The service provided is pre-paid, and the customer agrees to pay the monthly bill at the start of each service month.`,
        `The customer agrees to switch their home phone or cell phone services to ${companyName}, Inc from their current service provider.`,
        `${companyName}, Inc will make reasonable efforts to ensure that the customer retains their existing phone number. In cases where number portability is not possible, the customer will be notified`,
        `If the customer chooses not to utilize the selected services and equipment, without encountering any technical issues, the customer agrees to pay the specified monthly bill, including taxes.`,
        `The customer can choose any desired date for the monthly bill within the days provided by ${companyName}, Inc for the due date. In the event that the customer is unable to make the monthly payment on time, late fees, if applicable, will be applied to the bill.`,
        `By enrolling with ${companyName}, Inc., the customer consents to receive communications, including bills, notices, and updates, electronically.`,
        `${companyName}, Inc reserves the right to terminate the service in the event of non-payment or violation of the terms and conditions .`,
        `${companyName}, Inc will take reasonable measures to protect customer information and maintain data security.`,
        `${companyName}, Inc will provide customer support to the customer for any service-related queries or technical issues.`,
        `This agreement shall be governed by and construed in accordance with the laws of the applicable jurisdiction.`,
        <strong>I hereby certify that I have thoroughly read and agree to this disclosure.</strong>,
    ];
    if (parentDetails.accountType === "Postpaid") {
        checkBoxLabels = [
            `I hereby consent to enroll with ${companyName}, Inc for the selected services under the following terms and conditions: `,
            `${companyName}, Inc agrees to provide the selected equipment and services to the customer at the rates discussed with the customer`,
            `The service provided is post-paid, and the customer agrees to pay the monthly bill before the end of each service month.`,
            `The customer agrees to switch their home phone or cell phone services to ${companyName}, Inc from their current service provider.`,
            `${companyName}, Inc will make reasonable efforts to ensure that the customer retains their existing phone number. In cases where number portability is not possible, the customer will be notified`,
            `If the customer chooses not to utilize the selected services and equipment, without encountering any technical issues, the customer agrees to pay the specified monthly bill, including taxes.`,
            `The customer can choose any desired date for the monthly bill within the days provided by ${companyName}, Inc for the due date. In the event that the customer is unable to make the monthly payment on time, late fees, if applicable, will be applied to the bill.`,
            `By enrolling with ${companyName}, Inc., the customer consents to receive communications, including bills, notices, and updates, electronically.`,
            `${companyName}, Inc reserves the right to terminate the service in the event of non-payment or violation of the terms and conditions .`,
            `${companyName}, Inc will take reasonable measures to protect customer information and maintain data security.`,
            `${companyName}, Inc will provide customer support to the customer for any service-related queries or technical issues.`,
            `This agreement shall be governed by and construed in accordance with the laws of the applicable jurisdiction.`,
            <strong>I hereby certify that I have thoroughly read and agree to this disclosure.</strong>,
        ];
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />

                {/* <div>
                    <h5 className="font-bold">ENROLLMENT ID: {enrollment_id}</h5>
                </div> */}
                <br />
                <br />
                <div>
                    <p className="para">Please carefully read and agree by initialing all the boxes for the following statements. By clicking the boxes below, you agree to e-sign the statements with your initials and acknowledge that the initialed statements are enforceable</p>
                    <p className="font-semibold" style={{ fontFamily: "Inter", color: "#c68301" }}>
                        PLEASE CAREFULLY READ AND MARK ALL THE STATEMENTS AND ACCEPT THE TERMS AND CONDITIONS
                    </p>
                    <div className="field-checkbox ">
                        <Checkbox inputId="checkAll" onChange={handleAllNew} checked={checkAll} />
                        <label htmlFor="checkAll"> Select/Unselect All.</label>
                    </div>
                    <div className="p-3 ml-3">
                        {formik.errors.checkbox && formik.touched.checkbox && <div style={{ color: "red", marginBottom: "10px" }}>{formik.errors.checkbox}</div>}
                        {checkBoxLabels.map((label, index) => (
                            <div className="field-checkbox" key={index}>
                                <Checkbox inputId={`checkbox${index}`} onChange={() => handleCheckBox(index)} checked={formik.values.checkbox[index]} />
                                <label htmlFor={`checkbox${index}`}>{label}</label>
                            </div>
                        ))}
                    </div>
                    {/*<div>
                        <h3>Terms and Conditions</h3>
                        <div className="field-checkbox">
                            <Checkbox inputId="checkbox13" onChange={() => handleCheckBox(13)} checked={formik.values.checkbox[13]} />
                            <label>By signing the FCC application, I agree to accept the Terms & Conditions.</label>
                        </div>
                    </div>*/}
                </div>
                <div className="w-full flex flex-wrap flex-row justify-content-end">
                    <div className="flex flex-row justify-content-left align-items-center mb-2 sticky-buttons ">
                        <Button
                            className="btn"
                            label="Back"
                            type="button"
                            onClick={() => {
                                setAddressActive(true);
                                setConfirmationActive(false);
                            }}
                        />
                        <Button className="btn ml-2" label="Continue" type="submit" disabled={buttonClicked || formik.errors.checkbox || formik.values.checkbox.some((isChecked) => !isChecked)} icon={isLoading ? "pi pi-spin pi-spinner" : ""} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default Agree;
